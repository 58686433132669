import React, { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import StatisticsGraph from './StatisticsGraph'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { addDays, subDays } from "date-fns";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getDeviceStatistics } from '../../api/DeviceAPI';
import { getDataVariance, getMidnightDate, convertDateToIncludeOffset } from './Functions';
import { DotLoader } from 'react-spinners';
import AddchartIcon from '@mui/icons-material/Addchart';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { unitValues } from '../../constants/statVars';

export default function StatisticsGraphContainer({ chartIndex, deviceId, numGraphsDisplayed, setnumGraphsDisplayed, toggleAlert, statDatasets, serialNumber,
  finalStartDate,
  finalEndDate,
  finalGranularity,
  datasetDisplayDetails,
  datasets,
  graphComponentValues,
  yAxisConstantsState,
  claimedDatasetColors,
  unitIdInUse,
  setfinalStartDate,
  setfinalEndDate,
  setfinalGranularity,
  setdatasetDisplayDetails,
  setdatasets,
  setgraphComponentValues,
  setyAxisConstantsState,
  setclaimedDatasetColors,
  setunitIdInUse,
  syncLocked,
  setsyncLocked,
  syncedZoom,
  setsyncedZoom,
  syncedTranslateX,
  setsyncedTranslateX
}) {

  const [isLoading, setisLoading] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [granularity, setgranularity] = useState("HOURLY");
  const [dataUpdatedFlag, setdataUpdatedFlag] = useState(false);//true if data updated-> construct graphs
  const [localScrollAmount, setlocalScrollAmount] = useState(100); //Zoom func
  const [mouseIsDown, setmouseIsDown] = useState(false); //Panning
  const [mousePositionXY, setmousePositionXY] = useState({ x: 0, y: 0 }); //Zoom + Pan
  const [localGraphTranslateX, setlocalGraphTranslateX] = useState(0); //Panning
  const [mainCanvasWidthOnMouseDown, setmainCanvasWidthOnMouseDown] = useState(1000); //Panning
  const [showHoverAxis, setshowHoverAxis] = useState(false);
  const [hoverAxisCoordinates, sethoverAxisCoordinates] = useState({ x: 0, y: 0, time: '00:00' });
  const preciseTracerValues = useRef({ '0': null });
  const maxAmountOfDatasets = 4
  const dataSetColors = [
    '#a6cee3', '#2078b4', '#b2df8a', '#35a02c', '#f89a99',
    '#e31a1d', '#fabf6f', '#f77f02', '#cab2d6', '#6a3d9a'];
  const numVerticalLines = 12
  const numHorizontalLines = 9
  const graphViewBoxX = 1200
  const graphViewBoxY = 600
  const containerWidth = graphViewBoxX//graphViewBoxX
  const containerHeight = 600
  const yAxisValuesWidth = 60
  const xAxisValuesHeight = 30
  const graphWidth = containerWidth - yAxisValuesWidth//graphViewBoxX
  const graphHeight = containerHeight - xAxisValuesHeight

  const minInDay = 60 * 24
  const msInDay = 1000 * 60 * minInDay

  const setscrollAmount = (deltaY) => {
    if (syncLocked) {
      setsyncedZoom(prev => {
        if ((prev + deltaY <= 100)) {
          return 100
        } else if ((prev + deltaY >= 2400)) {
          return 2400
        } else {
          return prev + deltaY
        }
      })
    } else {
      setlocalScrollAmount(prev => {
        if ((prev + deltaY <= 100)) {
          return 100
        } else if ((prev + deltaY >= 2400)) {
          return 2400
        } else {
          return prev + deltaY
        }
      })
    }
  }
  const scrollAmount = () => {
    if (syncLocked) {
      return syncedZoom
    } else {
      return localScrollAmount
    }
  }
  const graphTranslateX = () => {
    if (syncLocked) {
      return syncedTranslateX
    } else {
      return localGraphTranslateX
    }
  }

  useEffect(() => {
    setstartDate(finalStartDate)
    setendDate(finalEndDate)
    setgranularity(finalGranularity)
  }, []);

  useEffect(() => {//update graphs if datasets or dates change
    if (dataUpdatedFlag) {
      const yAxisConstants = calcVarianceForAllDatasets()
      const startingTimestamp = finalGranularity === "HOURLY" ? getMidnightDate(finalStartDate) : new Date(finalStartDate)
      constructGraphs(startingTimestamp, yAxisConstants)
      setUnitInUse()
      setdataUpdatedFlag(false)
    }
  }, [dataUpdatedFlag]);

  function setUnitInUse() {
    let finalUnitId = null
    for (let index = 0; index < datasetDisplayDetails.length; index++) {
      const element = datasetDisplayDetails[index];
      if (element.statId) {
        const statId = element.statId //id of selected statObj
        const statIdKeys = statId.split("-")// eg. 'control-1'  
        const unitId = statDatasets[statIdKeys[0]][statIdKeys[1]].unitId
        finalUnitId = unitId
      }
    }
    setunitIdInUse(finalUnitId)
  }

  function calcVarianceForAllDatasets() {
    let finalVariance = { min: null, max: null }
    const valueKey = finalGranularity === "HOURLY" ? "value" : 'avg'
    Object.keys(datasets).forEach(datasetKey => {
      const variance = getDataVariance(datasets[datasetKey], valueKey)
      if (finalVariance.min === null || finalVariance.min > variance?.minValue) finalVariance.min = variance.minValue
      if (finalVariance.max === null || finalVariance.max < variance?.maxValue) finalVariance.max = variance.maxValue
    });
    setyAxisConstantsState(finalVariance)
    return finalVariance
  }

  function getOverflows(yAxisConstants) {
    const variance = yAxisConstants.max - yAxisConstants.min
    const mod = (variance) % numHorizontalLines
    let bottomOverflow = 0
    let topOverflow = 0
    if (mod >= 1 && mod <= 7) {//.0
      const invMod = numHorizontalLines - mod
      bottomOverflow = Math.floor(invMod / 2)
      topOverflow = invMod - bottomOverflow
    } else {//.5
      const modValue = mod === 0 ? numHorizontalLines : mod
      const invModCont = (numHorizontalLines + numHorizontalLines / 2) - modValue
      bottomOverflow = (invModCont + 0.5) / 2
      topOverflow = invModCont - bottomOverflow
    }
    return ({ top: topOverflow, bottom: bottomOverflow })
  }

  async function updateTimeframe() { //confirm clicked
    const paramstartDate = startDate
    const paramendDate = endDate
    const paramGranularity = granularity
    setfinalStartDate(paramstartDate)
    setfinalEndDate(paramendDate)
    setfinalGranularity(paramGranularity)
    if (datasetDisplayDetails.length > 0) {
      setisLoading(true)
      for (let index = 0; index < datasetDisplayDetails.length; index++) {
        const type = datasetDisplayDetails[index].type
        const datasetId = datasetDisplayDetails[index].datasetId
        await getDatasetStatistics(datasetId, type, paramstartDate, paramendDate, paramGranularity)
        if (index === datasetDisplayDetails.length - 1) {
          setdataUpdatedFlag(true)//trigger graph construction
          setisLoading(false)
          // console.log('#3')
        }
      }
    }
  }

  async function datasetSelected(datasetId, e) {
    if (e.target.value !== 0) {
      setisLoading(true)
      const statId = e.target.value //id of selected statObj
      const statIdKeys = statId.split("-")// eg. 'control-1'
      const type = statDatasets[statIdKeys[0]][statIdKeys[1]].type
      const datasetIndex = datasetDisplayDetails.findIndex(datasetObj => datasetObj.datasetId === datasetId)
      let copydatasetDisplayDetails = JSON.parse(JSON.stringify(datasetDisplayDetails))
      copydatasetDisplayDetails[datasetIndex] = { ...copydatasetDisplayDetails[datasetIndex], statId, type }
      setdatasetDisplayDetails(copydatasetDisplayDetails)
      await getDatasetStatistics(datasetId, type, finalStartDate, finalEndDate, finalGranularity)
      setdataUpdatedFlag(true)//trigger graph construction
      setisLoading(false)
    }
  };

  async function onGraphTypeChange(datasetId, checked) {
    const graphType = checked ? 'bar' : 'line'
    const datasetIndex = datasetDisplayDetails.findIndex(datasetObj => datasetObj.datasetId === datasetId)
    let copydatasetDisplayDetails = JSON.parse(JSON.stringify(datasetDisplayDetails))
    copydatasetDisplayDetails[datasetIndex] = { ...copydatasetDisplayDetails[datasetIndex], graphType }
    setdatasetDisplayDetails(copydatasetDisplayDetails)
  };

  function changeDatasetColor(datasetId, data) {
    let currentColorIndex = dataSetColors.findIndex(color => color === data.color)
    let newColorIndex = currentColorIndex
    while (claimedDatasetColors.includes(dataSetColors[newColorIndex])) {
      newColorIndex = newColorIndex + 1
      if (newColorIndex > dataSetColors.length - 1) {
        newColorIndex = 0
      }
    }
    const datasetIndex = datasetDisplayDetails.findIndex(datasetObj => datasetObj.datasetId === datasetId)
    setclaimedDatasetColors(prev => [...prev.filter((claimedColor) => claimedColor !== datasetDisplayDetails[datasetIndex].color), dataSetColors[newColorIndex]])
    let copydatasetDisplayDetails = JSON.parse(JSON.stringify(datasetDisplayDetails))
    copydatasetDisplayDetails[datasetIndex] = { ...copydatasetDisplayDetails[datasetIndex], color: dataSetColors[newColorIndex] }
    setdatasetDisplayDetails(copydatasetDisplayDetails)
  }

  async function getDatasetStatistics(datasetId, type, startDateIn, endDateIn, granularityIn) {
    if (type !== 0 && startDateIn) {
      let paramsStr = '?type=' + type + '&granularity=' + granularityIn
      const formattedStartDate = convertDateToIncludeOffset(startDateIn, true)
      paramsStr = paramsStr + '&startDate=' + (formattedStartDate)
      if (granularityIn === 'DAILY') {
        if (endDateIn) {
          const formattedEndDate = convertDateToIncludeOffset(endDateIn, true)
          paramsStr = paramsStr + '&endDate=' + (formattedEndDate)
        } else {
          const message = 'Please select an End Date'
          toggleAlert(true, 0, message)
          return
        }
      }
      const res = await getDeviceStatistics(deviceId, paramsStr)
      if (res) {
        setdatasets(prevDatasets => ({
          ...prevDatasets,
          [datasetId]: [...res],
        }));
      }
    }
  }

  function addDataset() {
    if (datasetDisplayDetails.length < maxAmountOfDatasets) {
      let colorIndex = 0
      for (let index = 0; index < dataSetColors.length; index++) {
        if (!claimedDatasetColors.includes(dataSetColors[index])) {
          colorIndex = index
          setclaimedDatasetColors(prev => [...prev, dataSetColors[index]])
          break;
        }
      }
      setdatasetDisplayDetails(prev => {
        const lastIndexId = prev[prev.length - 1]?.datasetId + 1 || 0//indexid of last value in array
        return [...prev, { datasetId: lastIndexId, type: 0, color: dataSetColors[colorIndex], graphType: 'bar' }]
      })
    }
  }

  function removeDataset(datasetIdIn) {
    //remove color reservation
    const datasetColorIndex = datasetDisplayDetails.findIndex(dataset => dataset.datasetId === datasetIdIn)
    setclaimedDatasetColors(prev => prev.filter((claimedColor) => claimedColor !== datasetDisplayDetails[datasetColorIndex].color))
    //remove graphData
    let copyDatasets = JSON.parse(JSON.stringify(datasets))
    delete copyDatasets[datasetIdIn]
    setdatasets(copyDatasets)
    //remove datasetdisplaydetails
    setdatasetDisplayDetails(prev => prev.filter((dataset) => dataset.datasetId !== datasetIdIn))
    //remove compiledGraphComponents
    let copyGraphComponentValues = JSON.parse(JSON.stringify(graphComponentValues))
    delete copyGraphComponentValues[datasetIdIn]
    setgraphComponentValues(copyGraphComponentValues)
    setdataUpdatedFlag(true)//trigger graph construction
  }

  const handleGraphIntervalChange = (type) => {
    setgranularity(type)
    setendDate(new Date())
    setstartDate(new Date())
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  function zoomGraph(e) {
    const mouseXPosition = e.clientX
    let containerSVGElement = document.getElementById("svg-axis-container-group-" + chartIndex)
    const canvasLeft = containerSVGElement.getBoundingClientRect().left
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    const canvasToMousePositionRatio = ((mouseXPosition - canvasLeft) / canvasWidth)//0 -> 1 (FINAL)--This the onnnneeee
    const transitionVal = (canvasToMousePositionRatio * (graphWidth) * e.deltaY) / 100
    setscrollAmount(e.deltaY)
    if (scrollAmount() < 2400) {//prevent translation on max zoomedIn
      if (syncLocked) {
        setsyncedTranslateX(prev => {
          if ((prev + transitionVal <= 0)) {
            return 0
          } else {
            return prev + transitionVal
          }
        })
      } else {
        setlocalGraphTranslateX(prev => {
          if ((prev + transitionVal <= 0)) {
            return 0
          } else {
            return prev + transitionVal
          }
        })
      }
    }
    return false
  }
  function handleMouseDown(e) {//Start panning
    let containerSVGElement = document.getElementById("svg-container-canvas-" + chartIndex)
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    setmainCanvasWidthOnMouseDown(canvasWidth)
    const mouseXPosition = e.clientX
    const mouseYPosition = e.clientY
    setmouseIsDown(true)
    setmousePositionXY({ x: mouseXPosition, y: mouseYPosition })
  }
  function handleMouseMove(event) {
    if (!mouseIsDown) {
      onHoverShowAxis(event)
    } else {
      const panMultiplier = containerWidth / mainCanvasWidthOnMouseDown //ensures mousePosiion correlates with translation
      const deltaX = (event.clientX - mousePositionXY.x) * panMultiplier;
      let canvasSVGElement = document.getElementById("svg-container-canvas-" + chartIndex) //container of displayed data (WINDOW)
      const canvasLeft = canvasSVGElement.getBoundingClientRect().left
      const canvasWidth = canvasSVGElement.getBoundingClientRect().width
      let containerSVGElement = document.getElementById("svg-axis-container-group-" + chartIndex) //displayed data
      const containerLeft = containerSVGElement.getBoundingClientRect().left
      const elementContainerWidth = containerSVGElement.getBoundingClientRect().width
      const canvasWidthMinusAxisTextWidth = canvasWidth * ((graphViewBoxX - yAxisValuesWidth) / graphViewBoxX)
      const axisContainerBaseLeft = canvasLeft + (canvasWidth * (yAxisValuesWidth / graphViewBoxX))
      if (syncLocked) {
        setsyncedTranslateX(prev => {
          if ((prev - deltaX <= 0)) {
            return 0
          } else if (((elementContainerWidth - (axisContainerBaseLeft - containerLeft)) <= (canvasWidthMinusAxisTextWidth)) && deltaX < 0) {
            return prev
          } else {
            return prev - deltaX
          }
        })
      } else {
        setlocalGraphTranslateX(prev => {
          if ((prev - deltaX <= 0)) {
            return 0
          } else if (((elementContainerWidth - (axisContainerBaseLeft - containerLeft)) <= (canvasWidthMinusAxisTextWidth)) && deltaX < 0) {
            return prev
          } else {
            return prev - deltaX
          }
        })
      }
      setmousePositionXY({ x: event.clientX, y: event.clientY });
    }
  }
  function handleMouseUp() { //Stop panning 
    if (!mouseIsDown) return
    setmouseIsDown(false)
    setmousePositionXY({ x: 0, y: 0 })
  }
  function onHoverShowAxis(event) {
    const mouseXPosition = event.clientX
    let containerSVGElement = document.getElementById("svg-axis-container-group-" + chartIndex)
    const canvasLeft = containerSVGElement.getBoundingClientRect().left
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    const canvasToMousePositionRatio = ((mouseXPosition - canvasLeft) / (canvasWidth))//0 -> 1 (FINAL)--This the onnnneeee
    if (canvasToMousePositionRatio < 0 || (canvasToMousePositionRatio > 1)) {
      if (showHoverAxis) setshowHoverAxis(false)
      return
    } else {
      if (!showHoverAxis) setshowHoverAxis(true)
    }
    const xCoordinateValue = canvasToMousePositionRatio * graphWidth
    let preciseTracerValObj = {}
    let finalXvalueForAll = 0
    const variance = yAxisConstantsState.max - yAxisConstantsState.min
    const verticalOverflows = getOverflows(yAxisConstantsState)
    const varianceWithOverflow = variance + verticalOverflows.top + verticalOverflows.bottom
    const compiledGraphComponentValues = graphComponentValues
    Object.keys(compiledGraphComponentValues).forEach(key => {
      if (compiledGraphComponentValues[key].length > 0) {
        let finalDataVal = null
        let finalXval = null
        let finalYval = null
        for (let index = 0; index < compiledGraphComponentValues[key].length; index++) {
          const value = compiledGraphComponentValues[key][index];
          if (xCoordinateValue > value.x) {
            const yCoordinateValue = value.y
            const yValOnCanvas = yCoordinateValue * varianceWithOverflow / (graphHeight)
            finalDataVal = varianceWithOverflow + (yAxisConstantsState.min - verticalOverflows.bottom) - (yValOnCanvas)
            finalXval = value.x
            finalYval = value.y
          }
        }
        const value = finalDataVal === null ? null : finalDataVal.toFixed(2)
        const preciseDataObj = { value, x: finalXval, y: finalYval }
        preciseTracerValObj[key] = preciseDataObj
        if (finalXval > finalXvalueForAll) {
          finalXvalueForAll = finalXval
        }
      }
    });
    const xDistanceOfAnHour = 47.5
    Object.keys(preciseTracerValObj).forEach(valueKey => { //display N/A if no value
      if (xCoordinateValue - xDistanceOfAnHour > finalXvalueForAll || preciseTracerValObj[valueKey].x < finalXvalueForAll) {
        preciseTracerValObj[valueKey].x = null
        preciseTracerValObj[valueKey].y = null
        preciseTracerValObj[valueKey].value = 'N/A'
      }
    });
    preciseTracerValues.current = preciseTracerValObj
    sethoverAxisCoordinates({ x: (canvasToMousePositionRatio * (graphWidth)), y: 0 })
  }
  const getGraphTransformation = () => { //Graph transformation based on zoom and pan amounts
    let xScale = scrollAmount() / 100
    // if (xScale < 1) xScale = 1
    let xTranslate = -graphTranslateX()
    // if (xTranslate > 0) xTranslate = 0
    const yScale = 1
    return 'translate(' + (xTranslate) + ', ' + 0 + ') scale(' + xScale + ', ' + yScale + ')'
  }
  function renderVerticalLines() { //For x-Axis values( date time ) {parallel to y-Axis}
    const lineDataArr = []
    const zoomScale = parseInt(scrollAmount() / 200)
    let renderMultiplier = (zoomScale >= 1) ? 2 : 1
    const verticalLinesToRender = finalGranularity === "HOURLY" ? numVerticalLines : (new Date(finalEndDate) - new Date(finalStartDate)) / msInDay
    let numLinesToRender = finalGranularity === "HOURLY" ? renderMultiplier * verticalLinesToRender : verticalLinesToRender
    for (let index = 0; index < numLinesToRender; index++) {
      const xValue = graphWidth / numLinesToRender * (index)
      const panningAdded = xValue
      const lineX = panningAdded
      lineDataArr.push({ x: lineX })
    }
    return lineDataArr
  }
  function renderHorizontalLines(graphType) {//For y-Axis values( temperature ) {parallel to x-Axis}
    const lineDataArr = []
    for (let index = 0; index < numHorizontalLines; index++) {
      const lineY = (graphHeight) / numHorizontalLines * (numHorizontalLines - index)
      lineDataArr.push({ y: lineY })
    }
    return lineDataArr
  }
  function renderVerticalText() { //Labels for x-Axis values (date time)
    const lineDataArr = []
    let xScale = scrollAmount() / 100
    const zoomScale = parseInt(scrollAmount() / 200)
    let renderMultiplier = (zoomScale >= 1) ? 2 : 1
    const verticalLinesToRender = finalGranularity === "HOURLY" ? numVerticalLines : (new Date(finalEndDate) - new Date(finalStartDate)) / msInDay
    let numLinesToRender = finalGranularity === "HOURLY" ? renderMultiplier * verticalLinesToRender : verticalLinesToRender
    for (let index = 0; index < numLinesToRender; index++) {
      const xValue = (xScale * graphWidth / numLinesToRender) * (index)
      let xTranslate = -graphTranslateX()
      // if (xTranslate > 0) xTranslate = 0
      const xValMinusLeft = xValue + xTranslate
      let finalValue = null
      if (finalGranularity === "HOURLY") {
        const totalMinutes = 1440 / numLinesToRender * index
        let hour = parseInt(totalMinutes / 60)
        let min = parseInt(totalMinutes % 60)
        let hourValue = '0' + hour
        let minValue = '0' + min
        const time = hourValue.slice(-2) + ":" + minValue.slice(-2)
        finalValue = time
      } else {
        const dateValue = addDays(finalStartDate, index)
        finalValue = dateValue.getDate() + "/" + (dateValue.getMonth() + 1)
      }
      lineDataArr.push({ x: xValMinusLeft, time: finalValue })
    }
    return lineDataArr
  }
  function renderHorizontalText() {//For y-Axis values( temperature ) {parallel to x-Axis}
    const lineDataArr = []
    const verticalOverflows = getOverflows(yAxisConstantsState)
    // console.log("Render: " + JSON.stringify({ ...yAxisConstantsState, ...verticalOverflows }))
    for (let index = 0; index < numHorizontalLines; index++) {
      const lineY = (graphHeight) / numHorizontalLines * (numHorizontalLines - index)
      const fullVarianceWithOverflows = (yAxisConstantsState.max + verticalOverflows.top) - (yAxisConstantsState.min - verticalOverflows.bottom)
      const calculatedValue = (index * (fullVarianceWithOverflows / numHorizontalLines)) + (yAxisConstantsState.min - verticalOverflows.bottom)
      const value = calculatedValue
      lineDataArr.push({ y: lineY, value: value.toFixed(1) })
    }
    return lineDataArr
  }
  //----------------------------------------------------------------------------------------------------------------------------------------------

  function constructGraphs(startingDateTime, yAxisConstants) {
    let allPathValuesObj = {} //for tracing {temp1: [{x: , y:}, {x: , y:}, ...]}
    const variance = yAxisConstants.max - yAxisConstants.min
    const verticalOverflows = getOverflows(yAxisConstants)
    const varianceWithOverflow = variance + verticalOverflows.top + verticalOverflows.bottom
    Object.keys(datasets).forEach((datasetId, index) => {
      const pathValues = []
      if (datasets[datasetId].length > 0) {
        for (let index = 0; index < datasets[datasetId].length; index++) {
          const pointData = datasets[datasetId][index];
          let dateTimeOnPoint = null
          let pointValue = null
          let timeElapsedFromStart = null
          let timeframe = null
          if (finalGranularity === "HOURLY") {
            dateTimeOnPoint = new Date(pointData.timestamp)
            pointValue = pointData.value
            timeElapsedFromStart = (dateTimeOnPoint - startingDateTime) / (1000 * 60)//inMinutes
            timeframe = minInDay
          } else {
            dateTimeOnPoint = new Date(pointData._id.date)
            pointValue = pointData.avg
            timeElapsedFromStart = parseInt((dateTimeOnPoint - startingDateTime) / msInDay + 1)//inDays ((+1)parse to get rounded)
            const daysFromStartToEnd = (new Date(finalEndDate) - new Date(finalStartDate)) / msInDay
            timeframe = daysFromStartToEnd
          }
          let xVal = timeElapsedFromStart
          let yVal = varianceWithOverflow - (pointValue) + (yAxisConstants.min - verticalOverflows.bottom)
          let xCoordinate = (graphViewBoxX - yAxisValuesWidth) / timeframe * xVal//HOURLY
          let yCoordinate = graphHeight / varianceWithOverflow * yVal
          pathValues.push({ x: xCoordinate, y: yCoordinate })
        }
        allPathValuesObj[datasetId] = pathValues
      }
    })
    const compiledCoordinateObj = getFlippedArraysIfWrongWay(allPathValuesObj)
    setgraphComponentValues(compiledCoordinateObj)
  }

  function getFlippedArraysIfWrongWay(allPathValuesObj) {
    let compiledCoordinateObj = {}
    Object.keys(allPathValuesObj).forEach(lKey => {
      const graphCoordinateArr = allPathValuesObj[lKey]
      //flip array if wrong order (compare first and last item in array to determine)
      if (graphCoordinateArr[0]?.x > graphCoordinateArr[graphCoordinateArr.length - 1]?.x) {
        const rev = graphCoordinateArr.reverse()
        compiledCoordinateObj[lKey] = rev
      } else {
        compiledCoordinateObj[lKey] = graphCoordinateArr
      }
    });
    return compiledCoordinateObj
  }

  function renderGraph() {
    const lineComponentsToRender = []
    const barComponentsToRender = []
    const numBarGraphsToRender = datasetDisplayDetails.filter(datasetObj => (datasetObj.graphType === "bar" && datasetObj.type !== 0)).length
    let numBarGraphsRendered = 0
    Object.keys(graphComponentValues).forEach((key, index) => {
      let pathString = ""
      const datasetIndex = datasetDisplayDetails.findIndex(dataset => dataset.datasetId + "" === key)
      const graphColor = datasetDisplayDetails[datasetIndex].color
      const graphValues = graphComponentValues[key]
      const graphType = datasetDisplayDetails[datasetIndex].graphType
      if (graphType === "line") {//line graph
        for (let index = 0; index < graphValues.length; index++) {
          if (index === 0) {
            pathString = "M"
          } else {
            let prevPoint = null;
            let pointTimeDifference = 0
            prevPoint = graphValues[index - 1];
            pointTimeDifference = graphValues[index].x - prevPoint.x
            pathString = pathString + " L"
          }
          const derivedX = graphValues[index].x
          const derivedY = graphValues[index].y
          pathString = pathString + derivedX + " " + derivedY
        }
        lineComponentsToRender.push(<path key={"l-" + key} strokeLinecap='round' strokeLinejoin='round' d={pathString} stroke={graphColor} strokeWidth={4} fill='none' vectorEffect={'non-scaling-stroke'} />)
      } else {//bar graph
        for (let index = 0; index < graphValues.length; index++) {
          const derivedX = graphValues[index].x
          const derivedY = graphValues[index].y
          // let pathString = "M" + derivedX + " " + derivedY + " L" + derivedX + " " + graphHeight
          // barComponentsToRender.push(<path key={"b-" + key + "-" + index} d={pathString} stroke={graphColor} strokeWidth={6} fill='none' vectorEffect={'non-scaling-stroke'} />)
          const barWidth = 45 / (numBarGraphsToRender)
          const xTranslate = 1.27 + (barWidth * numBarGraphsRendered)
          barComponentsToRender.push(<rect x={derivedX + xTranslate} y={derivedY} width={barWidth} height={graphHeight - derivedY}
            key={"b-" + key + "-" + index} stroke={'ffffffff'} strokeWidth={0} fill={graphColor} vectorEffect={'non-scaling-stroke'} />)
        }
        numBarGraphsRendered += 1
      }
    });
    const componentsToRender = [...barComponentsToRender, ...lineComponentsToRender] //lines always over graphs
    return (<>
      {componentsToRender}
    </>)
  }

  function timeframeClearClicked() {
    setstartDate(finalStartDate)
    setendDate(finalEndDate)
    setgranularity(finalGranularity)
  }

  const handleDate = (date) => {
    if (granularity === 'HOURLY') {
      setstartDate(date)
      setendDate(date)
    } else if (granularity === 'DAILY') {
      const [start, end] = date;
      setstartDate(start)
      setendDate(end)
    }
  }

  const displayAddRemoveGraphButton = () => {
    const keyValue = chartIndex + "-" + numGraphsDisplayed
    switch (keyValue) {
      case '0-1':
        return (<div className='add-remove-graph-button' onClick={() => setnumGraphsDisplayed(2)}>
          <AddchartIcon sx={{ color: 'var(--main-color)', cursor: 'pointer' }} />
        </div>)
      case '0-2':
        return (<div className='add-remove-graph-button' style={{
          backgroundColor: syncLocked ? 'var(--main-color' : 'whitesmoke'
        }}
          onClick={() => setsyncLocked(prev => !prev)}>
          <SyncLockIcon sx={{ color: syncLocked ? 'whitesmoke' : 'var(--main-color', cursor: 'pointer' }} />
        </div>)
      case '1-2':
        return (<div className='add-remove-graph-button' onClick={() => setnumGraphsDisplayed(1)}>
          <CloseIcon sx={{ color: 'var(--main-color)', cursor: 'pointer' }} />
        </div>)
      default:
        break;
    }
  }

  const getCompiledDatasetOptions = (datasetIdIn) => { //allow all if single unit selected else allow only selected unit Datasets
    const compiledArray = [...statDatasets.control, ...statDatasets.defrost, ...statDatasets.energy, ...statDatasets.ambient2]
    if (unitIdInUse !== null) {
      let statIdCount = 0
      datasetDisplayDetails.forEach(element => {
        if (element.statId && element.datasetId !== datasetIdIn) {
          statIdCount = statIdCount + 1
        }
      });
      if (statIdCount > 0) {
        const filteredArray = compiledArray.filter((statObj => statObj.unitId === unitIdInUse))
        return filteredArray
      } else {
        return compiledArray
      }
    } else {
      return compiledArray
    }
  }

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M10 20h4V4h-4zm-6 0h4v-8H4zM16 9v11h4V9z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#0b51b9',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="m2 19.99 7.5-7.51 4 4 7.09-7.97L22 9.92l-8.5 9.56-4-4-6 6.01zm1.5-4.5 6-6.01 4 4L22 3.92l-1.41-1.41-7.09 7.97-4-4L2 13.99z"/></svg>')`,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#003892',
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#aab4be',
      borderRadius: 20 / 2,
      ...theme.applyStyles('dark', {
        backgroundColor: '#8796A5',
      }),
    },
  }));


  return (
    <div className='stats-section'>
      <div className='stats-input-container'>
        <h4>{chartIndex === 0 ? serialNumber + ' Datasets' : 'Datasets'}</h4>
        {datasetDisplayDetails.map(((cardData, index) => {
          return (
            <div key={index} className='datasets-container-card'>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}>
                <Select
                  sx={{ height: '30px', width: '180px' }}
                  id="demo-simple-select"
                  value={cardData.statId || 0}
                  onChange={(e) => datasetSelected(cardData.datasetId, e)}>
                  <MenuItem value={0}>{''}</MenuItem>
                  {getCompiledDatasetOptions(cardData.datasetId).map(((selectData, index) => {
                    return (
                      <MenuItem key={index} value={selectData.id}>{selectData.name}</MenuItem>
                    )
                  }))}
                </Select>
                <CloseIcon style={{ cursor: 'pointer' }} onClick={() => removeDataset(cardData.datasetId)} />
              </div>
              <div style={{ width: '100%', height: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormControlLabel
                  style={{ scale: '0.8' }} control={<MaterialUISwitch checked={cardData.graphType === 'bar'}
                    onChange={(e) => onGraphTypeChange(cardData.datasetId, e.target.checked)} size='small' sx={{ m: 0 }} />} />
                <div onClick={() => changeDatasetColor(cardData.datasetId, cardData)} style={{ width: '38px', height: '18px', borderRadius: '90px', border: '1px solid black', backgroundColor: cardData.color }}></div>
                <p className='precise-stat' style={{ opacity: preciseTracerValues.current?.[cardData.datasetId]?.value ? 1 : 0 }}>{preciseTracerValues.current?.[cardData.datasetId]?.value}</p>
              </div>
            </div>
          )
        }))}
        {(datasetDisplayDetails.length) < maxAmountOfDatasets &&
          <AddCircleOutlineIcon onClick={() => addDataset()} style={{ cursor: 'pointer' }} />
        }
      </div>
      <div className='stats-graph-container'>
        <div className='stat-toolbar'>
          <RadioGroup
            row
            className='radio-btn-group'
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="HOURLY"
            name="radio-buttons-group"
            value={granularity}
            onChange={(e) => handleGraphIntervalChange(e.target.value)}>
            <FormControlLabel value="DAILY" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Daily" />
            <FormControlLabel value="HOURLY" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Hourly" />
          </RadioGroup>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
            <h4 style={{ margin: 0 }}>{"Date: "}</h4>
            <DatePicker
              target={granularity}
              showIcon
              todayButton="Today"
              dateFormat={"dd/MM/yyyy"}
              icon={<KeyboardArrowDownIcon />}
              toggleCalendarOnIconClick
              className="input-field statistics-datepicker"
              id={granularity === "HOURLY" ? 'hourly-date-picker' : 'daily-date-picker'}
              // minDate={subDays(new Date(), 30)}
              maxDate={(new Date())}
              autoComplete="off"
              showMonthDropdown
              showYearDropdown
              startDate={startDate}
              endDate={endDate}
              dropdownMode="select"
              placeholderText="DD/MM/YYYY"
              selected={startDate}
              selectsRange={granularity === 'HOURLY' ? false : true}
              onChange={(date) => handleDate(date)} />
          </div>
          <div className='check-box-container'>
            {isLoading ?
              <DotLoader size={29} color='#0b51b9' />
              :
              ((!(startDate === finalStartDate && endDate === finalEndDate && granularity === finalGranularity)) &&
                <>
                  <div onClick={() => updateTimeframe()}>
                    <CheckCircleIcon sx={{ color: 'var(--main-color)', cursor: 'pointer' }} />
                  </div>
                  <div onClick={() => timeframeClearClicked()}>
                    <HighlightOffIcon sx={{ color: 'var(--main-color)', cursor: 'pointer' }} />
                  </div>
                </>
              )
            }
          </div>
          {displayAddRemoveGraphButton()}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ height: '100%', margin: 0, width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h4 style={{ position: 'absolute', transform: 'rotate(-90deg)', margin: 0 }}>
              {(unitIdInUse === null) ? '' : unitValues[unitIdInUse].title + " (" + unitValues[unitIdInUse].unit + ")"}
            </h4>
          </div>
          <StatisticsGraph
            chartIndex={chartIndex}
            handleMouseDown={handleMouseDown}
            handleMouseUp={handleMouseUp}
            handleMouseMove={handleMouseMove}
            zoomGraph={zoomGraph}
            graphViewBoxX={graphViewBoxX}
            graphViewBoxY={graphViewBoxY}
            yAxisValuesWidth={yAxisValuesWidth}
            xAxisValuesHeight={xAxisValuesHeight}
            renderHorizontalText={renderHorizontalText}
            renderHorizontalLines={renderHorizontalLines}
            renderVerticalText={renderVerticalText}
            renderVerticalLines={renderVerticalLines}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            getGraphTransformation={getGraphTransformation}
            scrollAmount={scrollAmount()}
            renderGraph={renderGraph}
            numGraphsDisplayed={numGraphsDisplayed}
            showHoverAxis={showHoverAxis}
            hoverAxisCoordinates={hoverAxisCoordinates}
          />
        </div>
      </div>
    </div>
  )
}
