import React from 'react'
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import GraphContainer from '../GraphComponent/GraphContainer';
import 'react-datepicker/dist/react-datepicker.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CollapsedOverviewCard from '../PullupContainer/CollapsedOverviewCard';
import ExpandedOverviewCard from '../PullupContainer/ExpandedOverviewCard';
import { PuffLoader } from 'react-spinners';
import CustomCheckBox from '../CustomCheckBox';
import { dataColors } from '../../constants/colors';
import { iccOverviewData } from '../../constants/dataVars';

export default function LiveDataContainer({
  displayDataUpdated,
  setdisplayDataUpdated,
  deviceType,
  displayDate,
  tempTracersVisible,
  relayBarsVisible,
  targetOffsetsVisible,
  powerTracersVisible,
  toggleDataVisibility,
  tempDisplayData,
  relayDisplayData,
  powerDisplayData,
  powerFactorDisplayData,
  graphLoading,
  tempAxisConstants,
  powerAxisConstants,
  liveDeviceProps,
  deviceChannelDataLive,
  settempGraphComponentValues,
  tempGraphComponentValues,
  relayGraphComponentValues,
  setrelayGraphComponentValues,
  targetOffsetComponentValues,
  settargetOffsetComponentValues,
  powerGraphComponentValues,
  setpowerGraphComponentValues,
  powerFactorGraphComponentValues,
  setpowerFactorGraphComponentValues,
  deviceDetails,
  selectedMenuIndex,
  deviceChannelDataLoading,
  deviceChannelData,
  headerValue,
  handleShowEditChannelModal,
  slideStateId,
  setdateUpdatedFlag,
  selectedDisplayTab,
  allIndicatorsVisibleFor,
  setdisplayDate
}) {

  const displayLiveValue = (value, cardState) => {
    let valueFinal = 'N/A'
    const liveDataObj = cardState === "expanded" ? liveDeviceProps : deviceChannelDataLive
    if (liveDataObj[value.valueKey] || liveDataObj[value.valueKey] === 0) {
      if ('values' in value) {
        valueFinal = value.values[liveDataObj[value.valueKey]]
      } else {
        valueFinal = liveDataObj[value.valueKey].toFixed(2) + value.unit
      }
    }
    return valueFinal
  }

  return (
    <>
      <div className='overview-card-container' target={`_${selectedMenuIndex}`}>
        {(slideStateId === "slide-up") &&
          ((selectedMenuIndex === 1) ?
            <div className='top-display-select-container'>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "temp", allIndicatorsVisibleFor(tempTracersVisible))}
                  checked={allIndicatorsVisibleFor(tempTracersVisible)} />
                <p>All Temps</p>
              </div>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "relay", allIndicatorsVisibleFor(relayBarsVisible))} type="checkbox"
                  checked={allIndicatorsVisibleFor(relayBarsVisible)} />
                <p>All Relays</p>
              </div>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "target", allIndicatorsVisibleFor(targetOffsetsVisible))} type="checkbox"
                  checked={allIndicatorsVisibleFor(targetOffsetsVisible)} />
                <p>All Setpoints</p>
              </div>
            </div> :
            <></>
            // <div className='top-display-select-container'>
            //   <div className='row toggle-visibility-container' style={{ width: '100%' }}>
            //     <CustomCheckBox
            //       onChange={() => toggleDataVisibility(100, "power", allIndicatorsVisibleFor(powerTracersVisible))} type="checkbox"
            //       checked={allIndicatorsVisibleFor(powerTracersVisible)} />
            //     <p>All Duty Cycles</p>
            //   </div>
            // </div>
          )}
        {(slideStateId === "slide-up") ?
          <div className='card-scroll-container'>
            {deviceType === 0 ?
              iccOverviewData.expanded?.map((valueObj, index) => {
                return (
                  <div key={index} className='data-display-card-expanded' >
                    <div className='data-display-start'>
                      <h4 style={{ borderRadius: 6, padding: '3px 3px', color: 'white', backgroundColor: 'var(--main-color)' }}>{valueObj.title}</h4>
                    </div>
                    <div className='data-display-end'>
                      {valueObj.values.map((value, subIndex) => {
                        return (
                          <div key={subIndex} className='row' style={{ marginTop: '6px' }}>
                            {("colorIndex" in value) ?
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ width: '15px', height: '15px', borderRadius: '90px', marginRight: '3px', backgroundColor: dataColors[value.colorIndex] }}></div>
                                <p>{value.name}</p>
                              </div> :
                              <p>{value.name}</p>
                            }
                            <p>{displayLiveValue(value, 'expanded')}</p>
                          </div>)
                      })}
                    </div>
                  </div>
                )
              }) :
              deviceChannelData?.map((channelObj, index) => {
                return (
                  <ExpandedOverviewCard
                    key={index}
                    selectedMenuIndex={selectedMenuIndex}
                    channelObj={channelObj}
                    toggleDataVisibility={toggleDataVisibility}
                    tempTracersVisible={tempTracersVisible}
                    relayBarsVisible={relayBarsVisible}
                    targetOffsetsVisible={targetOffsetsVisible}
                    powerTracersVisible={powerTracersVisible}
                    handleShowEditChannelModal={handleShowEditChannelModal} />
                )
              })
            }
          </div>
          :
          <>
            {
              (deviceChannelDataLoading ?
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <PuffLoader
                    size={60}
                    color="#042855"
                  />
                </div>
                :
                <>
                  {deviceType === 0 ?
                    iccOverviewData.collapsed?.map((valueObj, index) => {
                      return (
                        <div key={index} className='data-display-card-collapsed icc-card' >
                          <div className='data-display-start'>
                            <h4 style={{ fontSize: '24px' }}>{valueObj.title}</h4>
                            <div />
                          </div>
                          <div className='data-display-end'>
                            {valueObj.values.map((value, subIndex) => {
                              return (<div key={subIndex} className='row' style={{ marginTop: '9px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p>{value.name}</p>
                                </div>
                                <p>{displayLiveValue(value, 'collapsed')}</p>
                              </div>)
                            })}
                          </div>
                        </div>
                      )
                    }) :
                    deviceChannelData?.map((channelObj, index) => {
                      return (
                        <CollapsedOverviewCard
                          key={index}
                          selectedMenuIndex={selectedMenuIndex}
                          channelObj={channelObj} />)
                    })
                  }
                </>
              )
            }
          </>
        }
      </div >
      <div className='overview-display-container' >
        <div className='overview-display-top'>
          <h1 className='overview-header'>
            {headerValue}
          </h1>
          <div style={{ opacity: selectedDisplayTab === 0 ? 1 : 0 }} className='overview-display-top-sub'>
            <div className="date-picker-container" style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
              <DatePicker
                showIcon
                todayButton="Today"
                dateFormat={"dd/MM/yyyy"}
                icon={<KeyboardArrowDownIcon />}
                toggleCalendarOnIconClick
                className="input-field"
                id="date-picker"
                minDate={subDays(new Date(), 30)}
                maxDate={(new Date())}
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DD/MM/YYYY"
                selected={displayDate}
                onChange={(date) => [setdisplayDate(date), setdateUpdatedFlag(true)]} />
              <h4 style={{ margin: '0px 6px', fontWeight: 500 }}>Display Date:</h4>
            </div>
          </div>
        </div>
        {
          slideStateId === "slide-up" &&
          <GraphContainer
            displayDataUpdated={displayDataUpdated}
            setdisplayDataUpdated={setdisplayDataUpdated}
            deviceType={deviceType}
            displayDate={displayDate}
            tempTracersVisible={tempTracersVisible}
            relayBarsVisible={relayBarsVisible}
            targetOffsetsVisible={targetOffsetsVisible}
            powerTracersVisible={powerTracersVisible}
            toggleDataVisibility={toggleDataVisibility}
            tempDisplayData={tempDisplayData}
            relayDisplayData={relayDisplayData}
            powerDisplayData={powerDisplayData}
            powerFactorDisplayData={powerFactorDisplayData}
            graphLoading={graphLoading}
            tempAxisConstants={tempAxisConstants}
            powerAxisConstants={powerAxisConstants}
            liveDeviceProps={liveDeviceProps}
            deviceChannelDataLive={deviceChannelDataLive}
            settempGraphComponentValues={settempGraphComponentValues}
            tempGraphComponentValues={tempGraphComponentValues}
            relayGraphComponentValues={relayGraphComponentValues}
            setrelayGraphComponentValues={setrelayGraphComponentValues}
            targetOffsetComponentValues={targetOffsetComponentValues}
            settargetOffsetComponentValues={settargetOffsetComponentValues}
            powerGraphComponentValues={powerGraphComponentValues}
            setpowerGraphComponentValues={setpowerGraphComponentValues}
            powerFactorGraphComponentValues={powerFactorGraphComponentValues}
            setpowerFactorGraphComponentValues={setpowerFactorGraphComponentValues}
          />
        }
        <div className='overview-display-bottom'>
          {deviceDetails.map((detail, index) => {
            return (
              <div key={index} className='device-detail-row' style={{ opacity: detail.opacity }}>
                <p>{detail.title} </p>
                <p>{detail.value}</p>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
