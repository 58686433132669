import React, { useEffect } from 'react'
import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'
import { useNavigate } from 'react-router-dom'

export default function Logout() {
    const navigate = useNavigate()

    useEffect(() => {
        handleNav()
    }, []);

    async function handleNav() {
        navigate('/')
    }

  return (
    <div>
      
    </div>
  )
}
