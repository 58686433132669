import { PubSub } from '@aws-amplify/pubsub';
import { fetchAuthSession } from 'aws-amplify/auth';


const getClientId = async () => {
    const credentials = await fetchAuthSession();
    const userToken = credentials?.tokens?.idToken
    return userToken.payload.sub
}

let singletonPubSub = null

export const getSingletonPubSub = async () => {
    if (singletonPubSub === null) {
        singletonPubSub = new PubSub({ //Connect to AWS PubSub
            region: 'eu-west-1',
            endpoint:
                `wss://${process.env.REACT_APP_IOT_ENDPOINT}/mqtt`
        });
    }
    return singletonPubSub
}
