import { urlConfig } from "./ApiConfig"
import { get, post, put } from "aws-amplify/api"

export async function getSimEvents(iccid) {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: `/gdsp/sims/${iccid}/events`
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function getSim(iccid) {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: `/gdsp/sims/${iccid}`
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function getAllSims() {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: '/gdsp/sims'
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function getServiceProfile() {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: '/gdsp/serviceProfiles'
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function updateSim(id, props) {
  const restOperation = put({
    apiName: 'fl2-rest-api-gateway',
    path: `/gdsp/sims/${id}`,
    options: {
      body: {
        ...props
      }
    }
  });

  return await restOperation.response
}
