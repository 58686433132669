import { urlConfig } from "./ApiConfig"
import { get, put } from "aws-amplify/api"

export async function updateChannelName(id, props) {
  try {
    const restOperation = put({
      apiName: 'fl2-rest-api-gateway',
      path: `/channels/${id}`,
      options: {
        body: {
          ...props
        }
      }
    });
    const { body } = await restOperation.response
    const json = await body.json()
    return json
  } catch (error) {
    return false
  }
}

