import React from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { dataColors } from '../../constants/colors';
import CustomCheckBox from '../CustomCheckBox';

export default function ExpandedOverviewCard({
    selectedMenuIndex,
    channelObj,
    toggleDataVisibility,
    tempTracersVisible,
    relayBarsVisible,
    targetOffsetsVisible,
    powerTracersVisible,
    handleShowEditChannelModal,
}) {

    const color = selectedMenuIndex === 0 ? (channelObj?.chanen === 0 ? "lightgray" : 'black') : (channelObj?.cMode === 0 ? "lightgray" : 'black')
    const opacity = selectedMenuIndex === 0 ? (channelObj?.chanen === 0 ? 0.4 : 1) : (channelObj?.cMode === 0 ? 0.4 : 1)
    const channelName = channelObj?.name ?? '';
    const channelID = channelObj._id;
    const CASEMmodes = ['-', "On-Off", "Proportional"]
    const index = channelObj?.index

    return (
        <div className='data-display-card-expanded' style={{ opacity }}>
            <div className='data-display-start'>
                <h4 style={{ backgroundColor: dataColors[index], borderRadius: 9, padding: '0px 6px', color: 'white' }}>{channelName}</h4>
                {selectedMenuIndex === 0 &&
                    <div className='edit-button-container'>
                        <EditOutlinedIcon className='edit-icon' sx={{ color: { color } }} onClick={() => {
                            handleShowEditChannelModal(true, channelID, channelName)
                        }} />
                    </div>
                }
            </div>
            {(selectedMenuIndex === 0 || selectedMenuIndex === 1) ?
                <div className='data-display-end'>
                    <div className='row'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <CustomCheckBox
                                onChange={() => toggleDataVisibility(index, "temp")}
                                checked={tempTracersVisible[index] || false} />
                            <p>Temp:</p>
                        </div>
                        <p>{channelObj?.temp ? channelObj?.temp.toFixed(2) + '\u00b0C' : 'N/A'}</p>
                    </div>
                    <div className='row'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <CustomCheckBox
                                onChange={() => toggleDataVisibility(index, "relay")}
                                checked={relayBarsVisible[index] || false} />
                            <p>Rel state:</p>
                        </div>
                        <p>{channelObj?.relState ? channelObj?.relState:'N/A'}</p>
                    </div>
                    <div className='row'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <CustomCheckBox
                                onChange={() => toggleDataVisibility(index, "target")}
                                checked={targetOffsetsVisible[index] || false} />
                            <p>Setpoint</p>
                        </div>
                        <p>{channelObj?.setpoint ? channelObj?.setpoint:"N/A"}</p>
                    </div>
                    <div className='row'>
                        <p>Differential:</p>
                        <p>{channelObj?.diff}</p>
                    </div>
                </div>
                :
                <div className='data-display-end'>
                    <div className='row'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <CustomCheckBox
                                onChange={() => toggleDataVisibility(index, "dutyCycle")}
                                checked={powerTracersVisible[index] || false} />
                            <p>Duty Cycle:</p>
                        </div>
                        <p>{channelObj?.dCycle + '%'}</p>
                    </div>
                    <div className='row'>
                        <p>Threshold Temp:</p>
                        <p>{channelObj?.cThresh + '\u00b0C'}</p>
                    </div>
                    <div className='row'>
                        <p>Control Type:</p>
                        <p>{CASEMmodes[channelObj?.cMode]}</p>
                    </div>
                    <div className='row'>
                        <p>{channelObj?.cMode === 1 ? "Strength:" : "Max temp:"}</p>
                        <p>{channelObj?.cMode === 1 ? (channelObj?.hStren + "%") : (channelObj?.mTemp + '\u00b0C')}</p>
                    </div>
                </div>
            }
        </div>
    )
}
