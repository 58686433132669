export function getDataVariance(data, valueKey) { //get highest and lowest value on datapoints
    let minValue = null
    let maxValue = null
    // data.forEach((dataPoint) => {
    for (let index = 0; index < data?.length; index++) {
        const pointDataValue = data[index][valueKey];
        if (pointDataValue < minValue || minValue === null) minValue = pointDataValue
        if (pointDataValue > maxValue || maxValue === null) maxValue = pointDataValue
    }
    // })
    // if (minValue === null || maxValue === null) return
    return { minValue, maxValue }
}

export function getMidnightDate(dateIn) {
    const dateFRMT = new Date(dateIn)
    // const offsetMin = dateFRMT.getTimezoneOffset()
    const offsetMin = 0

    dateFRMT.setHours(0)
    dateFRMT.setMinutes(0)
    dateFRMT.setSeconds(0)
    dateFRMT.setTime(dateFRMT.getTime() + (offsetMin * 60 * 1000))
    return dateFRMT
}

export function isSameDay(dateA, dateB) {
    const date1 = new Date(dateA); // December 25, 2023
    const date2 = new Date(dateB); // December 25, 2023, 12:34:56
    // Ensure both arguments are valid Date objects
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        throw new TypeError('Both arguments must be Date objects');
    }
    // Compare year, month, and day components
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function isLater(dateA, dateB) {
    const date1 = new Date(dateA); // December 25, 2023
    const date2 = new Date(dateB); // December 25, 2023, 12:34:56
    if (date1.getTime() < date2.getTime()) {
        return true
    } else {
        return false
    }
}

export function convertDateToIncludeOffset(dateIn, encode) {
    let isoStringWithoutOffset = dateIn.toISOString();
    // Extract the time zone offset from the date
    const offsetMinutes = dateIn.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetSign = offsetMinutes < 0 ? '+' : '-';
    const offsetString = `${offsetSign}${padZeroes(offsetHours)}:00`;
    // Add the time zone offset to the ISO string
    const isoStringWithOffset = isoStringWithoutOffset.replace('Z', offsetString);
    // Function to pad zeroes to single-digit numbers
    function padZeroes(num) {
        return num.toString().padStart(2, '0');
    }
    let dateOut = encode ? encodeURIComponent(isoStringWithOffset) : isoStringWithOffset
    return dateOut
}
