import { urlConfig } from "./ApiConfig"
import { get, put } from "aws-amplify/api"

export async function getAllDevices(devicetype) {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: '/devices',
            options: {
                queryParams: {
                    type: devicetype,
                }
            }
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.stringify(error))
        return false
    }
}

export async function getDeviceChannelsById(id) {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${id}/channels`
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.stringify(error))
        return false
    }
}

export async function getDeviceTelemetryById(id, type, date) {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${id}/telemetry`,
            options: {
                queryParams: {
                    // type,
                    date,
                }
            }
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("aSUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.stringify(error))
        return false
    }
}

export async function getDeviceLogs(deviceId, limit) {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${deviceId}/logs`,
            options: {
                queryParams: {
                    limit
                }
            }
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.stringify(error))
        return false
    }
}

export async function editDevice(deviceId, props) {
    try {
        const restOperation = put({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${deviceId}`,
            options: {
                body: {
                    ...props
                }
            }
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.parse(error.response.body))
        return false
    }
}

export async function getDeviceStatistics(id, paramsStr) {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${id}/statistics${paramsStr}`
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.stringify(error))
        return false
    }
}

export async function getDeviceModules(id) {
  try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: `/devices/${id}/modules`
        });
        const { body } = await restOperation.response
        const json = await body.json()
        console.log("SUCCESS: GETTING DEVICE MODULES")
        return json
  } catch (error) {
    return false
  }
}


// export async function verifyDeviceCode(deviceId, deviceCode) {

// }

