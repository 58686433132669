import { useState } from 'react'
import './styles/EditChannelModal.css'
import { updateChannelName } from '../api/ChannelAPI';
import CloseIcon from '@mui/icons-material/Close';
import { SyncLoader } from 'react-spinners';


const EditChannelModal = ({ show, handleShowEditChannelModal, editChannel, onNameSaved }) => {
    const [newChannelName, setNewChannelName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleNameInput = (e) => {
        setNewChannelName(e)
    }

    const handleSave = async () => {
        setIsLoading(true)

        const response = await updateChannelName(editChannel.channelID, { name: newChannelName })

        if (response === "Sucessfully updated channel") {
            handleShowEditChannelModal(false)
            onNameSaved(editChannel.channelID, newChannelName)
        }

        setIsLoading(false)
    }

    return (
    <>
      { show && (
        <div className='edit-shelf-modal'>
            <div className='edit-shelf-backdrop' onClick={() => handleShowEditChannelModal(false)}></div>
            <div className='edit-shelf-modal-content'>
                <div className='edit-shelf-modal-header'>
                    <h2>{editChannel.channelName}</h2>
                    <CloseIcon className='close-icon' onClick={() => handleShowEditChannelModal(false)} />
                </div>
                <div className='edit-shelf-modal-body'>
                    <p>Channel Name:</p>
                    <input type='text' onChange={(e) => { handleNameInput(e.target.value) }} />
                    <button onClick={() => { handleSave() }}>
                        {isLoading ?
                            <SyncLoader
                                height="27"
                                width="27"
                                color='#ffffff'
                                strokeWidth={5}
                            /> : "Save"}
                    </button>
                </div>
            </div>
        </div>
      )}
    </>
    )
}

export default EditChannelModal
