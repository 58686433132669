// import { React, useState } from 'react'
import logo from '../assets/ColcabLogoNoText.png'
// import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
// import { Oval } from 'react-loader-spinner';


const ForgotPassword = () => {
    // const [displayMode, setDisplayMode] = useState(1); // 1 = send link, 2 = enter code, 3 = set new password
    // const [username, setUsername] = useState('');
    // const [confirmationCode, setConfirmationCode] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    // const handleUsernameChange = (value) => {
    //     setUsername(value);
    // }

    // const handleCodeConfirmation = (value) => {
    //     setConfirmationCode(value);
    // }


    // async function handleResetPassword(username) {
    //     try {
    //         setIsLoading(true);
    //         const output = await resetPassword({ username });
    //         handleResetPasswordNextSteps(output);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // function handleResetPasswordNextSteps(output) {
    //     const { nextStep } = output;
    //     console.log(nextStep);
    //     switch (nextStep.resetPasswordStep) {
    //         case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
    //             const codeDeliveryDetails = nextStep.codeDeliveryDetails;
    //             setIsLoading(false);
    //             alert(`Confirmation code has been sent`);
    //             handleCodeConfirmation()
    //             break;
    //         case 'DONE':
    //             console.log('Successfully reset password.');
    //             break;
    //         default:
    //             console.log('Invalid reset password step.');
    //     }
    // }

    // async function handleConfirmResetPassword({
    //     username,
    //     confirmationCode,
    //     newPassword
    // }) {
    //     try {
    //         await confirmResetPassword({ username, confirmationCode, newPassword });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    return (
        <div className='forgot-container'>
            <div className='forgot-card'>
                <div className='logo-container'>
                    <img src={logo} className='logo' alt='logo' />
                </div>

                <div className='forgot-form'>
                    <div className='forgot-wrapper'>
                        <h1 className='forgot-title'>Forgot Password</h1>
                        <input type='email' placeholder='Email' className='forgot-input' />
                        <button className='forgot-button' >
                            {'Send Link'}</button>
                    </div>
                </div>
            </div>
        </div>
    )




}

export default ForgotPassword