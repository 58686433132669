import { useEffect, useState, useRef } from 'react'
import { getSingletonPubSub } from '../../mqtt/MyPubSub'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ClipLoader } from "react-spinners";
import { getFirmwareByType } from '../../api/FirmwareAPI';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FirmwareTableRow = ({
  module,
  firmwareLoaders,
  setFirmwareLoaders,
  firmwareValues,
  setFirmwareValues,
  newFirmwareValues,
  setNewFirmwareValues,
  firmwareLoadersColors,
  setFirmwareLoadersColors,
  initialLoader,

}) => {
  const [firmwareVersions, setFirmwareVersions] = useState([])
  const [singleFirmwareVersion, setSingleFirmwareVersion] = useState(module.firmwareVersion)
  const [loadError, setLoadError] = useState(false)
  const [localLoader, setLocalLoader] = useState(null)

  useEffect(() => {
    if (firmwareLoaders[module.alias] !== localLoader) {
      setLocalLoader(firmwareLoaders[module.alias])
    } 
  }, [firmwareLoaders])

  const handleFirmwareInput = (value) => {
    const firmware = firmwareVersions[value]
    if (firmware.type === 'ccm_telemetry') {
      setNewFirmwareValues(prev => ({ ...prev, firmVTelem: firmware }))
    } else if (firmware.type === 'ccm_control') {
      setNewFirmwareValues(prev => ({ ...prev, firmVCtrl: firmware }))
    }
  }

  const clearFirmware = (message) => {
    if (newFirmwareValues[message.measureName] === message.value || firmwareValues[message.measureName] === null) {
      setFirmwareValues(prev => ({ ...prev, [message.measureName]: message.value }))
      setFirmwareLoaders(prev => ({ ...prev, [module?.alias]: false }))
      const copyNewFirmwareValues = { ...newFirmwareValues }
      delete copyNewFirmwareValues[message.measureName]
      setNewFirmwareValues(copyNewFirmwareValues)
    }
  }

  useEffect(() => {
    let ref = null
    const getFirmwareVersions = async () => {
      const allFirmwareVersions = await getFirmwareByType(module.firmwareType)
      if (allFirmwareVersions) {
        setFirmwareVersions(allFirmwareVersions)
      }
      const pubsub = await getSingletonPubSub()
      ref = pubsub.subscribe({
        topics: [module.topic]
      }).subscribe({
        next: (data) => {
          setSingleFirmwareVersion(data.value)
          clearFirmware(data)
        },
        error: (error) => console.error(error),
        complete: () => console.log("done")
      })
    }

    getFirmwareVersions()
  }, [])

  const generateLoaders = () => {
    if (loadError) {
      return <ErrorOutlineIcon sx={{ height: '100%', color: 'red' }} />
    } else {
      if (!firmwareLoaders[module?.alias]) {
        return <CheckCircleOutlineIcon sx={{ height: '100%', color: firmwareLoadersColors[module?.alias] }} />
      } else {
        return <ClipLoader size={18} />
      }
    }
  }

  const loaderTimeout = useRef(null)
  useEffect(() => {
    if (localLoader === null) return

    if (localLoader === true) {
      loaderTimeout.current = setTimeout(() => {
        // setSettingsLoaders(prev => ({ ...prev, [keyValue]: null }))
        setLoadError(true)
      }, 10000);
    } else {
      clearTimeout(loaderTimeout.current)
    }
  }, [localLoader])

  return (
    <div className='row' >
      <div className='column'>
        <p>
          {module.name}
        </p>
      </div>
      <div className='column'>
        <div>
          {singleFirmwareVersion}
        </div>
      </div>
      <div className='column'>
        <select onChange={(e) => { handleFirmwareInput(e.target.value) }}>
          <option value={''}></option>
          {firmwareVersions.map((version, index) => (
            <option key={index} value={index}>{version.versionName}</option>
          ))}
        </select >
      </div>
      <div className='column'>
        <div >
          {generateLoaders()}
        </div>
      </div>
    </div>
  )
}

export default FirmwareTableRow;
