import React, { useState, useEffect } from 'react'

export default function StatisticsGraph({
    chartIndex,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    zoomGraph,
    graphViewBoxX,
    graphViewBoxY,
    yAxisValuesWidth,
    xAxisValuesHeight,
    renderHorizontalText,
    renderHorizontalLines,
    renderVerticalText,
    renderVerticalLines,
    containerWidth,
    containerHeight,
    graphWidth,
    graphHeight,
    getGraphTransformation,
    scrollAmount,
    renderGraph,
    numGraphsDisplayed,
    showHoverAxis,
    hoverAxisCoordinates }) {

    return (
        <svg id={'svg-container-canvas-' + chartIndex}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => handleMouseUp()}
            onWheel={zoomGraph}
            style={{ width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
            viewBox={'0 0 ' + (graphViewBoxX) + ' ' + (graphViewBoxY)}>
            <g id={'hor-text-container-'+chartIndex} transform={'translate(' + yAxisValuesWidth + ', -' + (xAxisValuesHeight - 30) + ')'}>
                {renderHorizontalText("temp").map((line, index) => {
                    return (
                        <g key={index}>
                            <text style={{ userSelect: 'none' }} textAnchor='middle' fontWeight={600} fontSize={numGraphsDisplayed === 2 ? 24 : 15}
                                y={line.y + 3} x={-24} fill="black">{(line.value)}</text>
                        </g>
                    )
                })}
            </g>
            <svg id={'svg-data-container-' + chartIndex}
                y={0}
                width={containerWidth} height={containerHeight}
                viewBox={'0 0 ' + (containerWidth) + ' ' + (containerHeight)} >
                <g transform={'translate(' + yAxisValuesWidth + ', -' + (xAxisValuesHeight - 30) + ')'}>
                    {renderHorizontalLines("stat").map((line, index) => {
                        return (
                            <g key={index}>
                                <line y1={line.y} x1="0" y2={line.y} x2={graphWidth} stroke='#00000033' strokeWidth={1} />
                            </g>
                        )
                    })}
                </g>
                <g transform={'translate(' + yAxisValuesWidth + ')'}>
                    <svg id={'svg-axis-container-' + chartIndex}
                        width={graphWidth} height={graphHeight}
                        viewBox={'0 0 ' + (graphWidth) + ' ' + (graphHeight)}>
                        <g id={'svg-axis-container-group-' + chartIndex} transform={getGraphTransformation()}>
                            <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                            {renderVerticalLines().map((line, index) => {
                                return (
                                    <line key={index} x1={line.x} y1="0" x2={line.x} y2={graphHeight} stroke='#00000033' strokeWidth={100 / scrollAmount} />
                                )
                            })}
                            {renderGraph()}
                            {showHoverAxis &&
                                <line id='tracer-line-top' x1={hoverAxisCoordinates.x} x2={hoverAxisCoordinates.x} y1={0} y2={graphHeight} stroke='red' strokeWidth={100 / scrollAmount}></line>
                            }
                        </g>
                    </svg>
                </g>
                <g transform={'translate(' + (yAxisValuesWidth - 33) + ',' + graphHeight + ')'}>
                    <svg id={'svg-y-axis-title-container-'+chartIndex}
                        width={graphWidth + 33} height={xAxisValuesHeight}
                        viewBox={'0 0 ' + (graphWidth + 33) + ' ' + (xAxisValuesHeight)}>
                        <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                        {renderVerticalText().map((line, index) => {
                            return (
                                <text style={{ userSelect: 'none' }} key={index} fontSize={numGraphsDisplayed === 2 ? 24 : 15} fontWeight={600} textAnchor='middle' x={line.x + 33} y={24} fill="black">{line.time}</text>
                            )
                        })}
                    </svg>
                </g>
                <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
            </svg>
            <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
        </svg>
    )
}
