import { Add } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { ReactComponent as AscIcon } from '../../assets/ascendingIcon.svg';
import { ReactComponent as DescIcon } from '../../assets/descendingIcon.svg';
import { menuItems } from '../../constants/dataVars';
import SearchBar from './SearchBar';
import './styles/TopToolbar.css';

export default function TopToolbar({
  selectedMenuIndex,
  searchTerm,
  orderBy,
  sortBy,
  filters,
  changeSearchSortOrderFilter,
  toggleModal }) {

  const handleSortDropdown = (value) => {
    changeSearchSortOrderFilter('sort', value)
  }

  const handleOrderClick = () => {
    orderBy === 'asc' ? changeSearchSortOrderFilter('order', 'desc') : changeSearchSortOrderFilter('order', 'asc')
  }

  const menuItemInfo = menuItems[selectedMenuIndex]
  const sortTitles = menuItemInfo.sortValue.title
  const sortKeys = menuItemInfo.sortValue.sortKeys

  return (
    <div className='top-toolbar'>
      <div className='data-tools-container'>
        {menuItemInfo.search &&
          <SearchBar
            searchTerm={searchTerm}
            changeSearchSortOrderFilter={changeSearchSortOrderFilter} />
        }
        {(menuItemInfo.sort || menuItemInfo.order || menuItemInfo.filter) &&
          <div className='sort-filter-container'>
            {menuItemInfo.sort &&
              <div className='tool-container'>
                <h5 >Sort:</h5>
                <select value={sortBy} className='sort-filter-dropdown' onChange={(e) => handleSortDropdown(e.target.value)}>
                  <option value={'none'}>None</option>
                  {sortTitles.map((value, index) => {
                    return (
                      <option key={index} value={sortKeys[index]}>{value}</option>
                    )
                  })}
                </select>
              </div>}
            {menuItemInfo.order &&
              <div className='order-container tooltip-icon' onClick={() => handleOrderClick()}>
                {orderBy === 'desc' ? <DescIcon className='order-icon' /> : <AscIcon className='order-icon' />}
                <span className='tooltip-text'>Sort Order</span>
              </div>}
            {menuItemInfo.filter &&
              <div className='tool-container'>
                <h5>Filter:</h5>
                <div onClick={() => changeSearchSortOrderFilter('filter', 1)}
                  className={'filter-radio-btn' + (filters[1] === true ? ' filter-radio-btn-selected' : '')}>
                  {"Online"}
                </div>
                <div onClick={() => changeSearchSortOrderFilter('filter', 2)}
                  className={'filter-radio-btn' + (filters[2] === true ? ' filter-radio-btn-selected' : '')}>
                  {"Disconnected"}
                </div>
                <div onClick={() => changeSearchSortOrderFilter('filter', 0)}
                  className={'filter-radio-btn' + (filters[0] === true ? ' filter-radio-btn-selected' : '')}>
                  {"Off"}
                </div>
              </div>}
          </div>
        }

      </div>
      <div className='action-container'>
        {menuItemInfo.showAddButton &&
          <div onClick={() => toggleModal(true, selectedMenuIndex)} className='add-record-button tooltip-icon'>
            <span className='tooltip-text'>Add Record</span>
            <Add sx={{ color: 'white' }} />
          </div>
        }
      </div>
    </div>
  )
}
