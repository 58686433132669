import { urlConfig } from "./ApiConfig"
import { get, post, del } from "aws-amplify/api"

export async function getAllFirmware() {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: '/firmware'
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function addFirmware(props) {
  try {
    const restOperation = post({
      apiName: 'fl2-rest-api-gateway',
      path: '/firmware',
      options: {
        body: {
          ...props
        }
      }
    });
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function getFirmwareByType(type) {
  try {
    const restOperation = get({
      apiName: 'fl2-rest-api-gateway',
      path: `/firmware`,
      options : {
        queryParams : {
          type: type
        }
      }
    },
    );
    const { body } = await restOperation.response
    const json = await body.json()
    // console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}

export async function deleteFirmware(id) {
  try {
    const restOperation = del({
      apiName: 'fl2-rest-api-gateway',
      path: `/firmware/${id}`
    });
    const { body } = await restOperation.response
    const json = await body.json()
    console.log("SUCCESS: ", JSON.stringify(json))
    return json
  } catch (error) {
    // console.log("FAIL: ", JSON.stringify(error))
    return false
  }
}
