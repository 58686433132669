import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './styles/Modals.css'
import { SyncLoader } from 'react-spinners';
import { addCustomer, editCustomer } from '../api/CustomerAPI';
import { uploadData } from 'aws-amplify/storage';
import { addFirmware, deleteFirmware } from '../api/FirmwareAPI';

export default function AddEditFirmware({ show, toggleModal, submit, modalInEditMode, selectedRecord, deleteClicked, toggleAlert, getDataFromDatabase }) {

  const [type, settype] = useState("");
  const [versionName, setversionName] = useState("");
  const [description, setdescription] = useState("");
  const [file, setFile] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (show && modalInEditMode) {
      populateFields()
    } else {
      clearFields()
    }
  }, [show]);

  function populateFields() {
    settype(selectedRecord.type)
    setversionName(selectedRecord.versionName)
    setdescription(selectedRecord.description)
    setFile("")
  }

  function clearFields() {
    settype("")
    setversionName("")
    setdescription("")
    setFile("")
  }

  function onChange(event) {
    switch (event.target.name) {
      case "type":
        settype(event.target.value)
        break;
      case "versionName":
        setversionName(event.target.value)
        break;
      case "description":
        setdescription(event.target.value)
        break;
      case "file":
        setFile(event.target.files[0])
        break;
      default:
        break;
    }
  }

  async function onSubmit() {
    //validation
    try {
      setisLoading(true)

      if (type && versionName && file) {
        const key = `${type}/${versionName}/${file.name}`;

        const props = { type, versionName, description, bucket: 'dev-fridgelink2-firmware', key };

        const res = await addFirmware(props);

        if (res?._id) {
          toggleAlert(true, 2, "New Firmware Version added")
          handleClose()
          getDataFromDatabase(4, true)
        } else {
          // message = res?.errors?.[0]?.message || message
          toggleAlert(true, 0, "An Error occured")
        }

        const uploadResult = await uploadData({
          path: key,
          data: file,
          options: {
            bucket: 'firmware',
          }
        }).result;
      } else {
        handleClose()
        toggleAlert(true, 0, "Fill in all fields")
        return
      }
    } catch (err) {
      toggleAlert(true, 0, err)
    } finally {
      setisLoading(false)
    }
  }

  const handleDeleteClicked = async () => {
    try {
      const res = await deleteFirmware(selectedRecord._id)
      if (res) {
        toggleAlert(true, 2, res)
      }
    } catch (e) {
      toggleAlert(true, 0, e)
    } finally {
      handleClose()
    }
  }

  function handleClose() {
    //close method
    clearFields()
    toggleModal(false, 5)
  }

  return (
    <>
      {show ? (
        <div className='backdrop' onClick={handleClose}>
          <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
            <div className="modal-header" >
              <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Firmware"}</h3>
              <CloseIcon onClick={() => { handleClose() }} sx={{ cursor: 'pointer' }} />
            </div>
            {/* body container */}
            <form className='input-form'  >
              <div className="form-group-vertical">
                <label htmlFor="type" className="col-form-label">Type</label>
                <select value={type} type="text" autoComplete='off' id='type' placeholder='' className="modal-input" name="type" onChange={onChange} >
                  <option></option>
                  <option>ccm_control</option>
                  <option>ccm_telemetry</option>
                </select>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="versionName" className="col-form-label">Version Name</label>
                <input value={versionName} type="text" autoComplete='off' id='versionName' placeholder='' className="modal-input" name="versionName" onChange={onChange} ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="description" className="col-form-label">Description</label>
                <textarea value={description} type="text" autoComplete='off' id='description' placeholder='' className="modal-input" name="description" onChange={onChange} style={{ height: 80 }} ></textarea>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="file" className="col-form-label">File</label>
                <input type="file" autoComplete='off' id='file' placeholder='' className="modal-input" name="file" onChange={onChange} ></input>
              </div>
            </form>
            <div className='submit-container'>
              <button className='submit-btn remove-btn' disabled={!modalInEditMode}
                onClick={() => handleDeleteClicked()}
                style={{
                  cursor: modalInEditMode ? "pointer" : 'default',
                  opacity: modalInEditMode ? 1 : 0
                }}>{"Delete"}</button>
              <button className='modal-submit-btn' onClick={() => { onSubmit() }} >
                {isLoading ?
                  <SyncLoader
                    size={6}
                    color="#ffffff"
                  /> : "Submit"
                }
              </button>
            </div>
          </div>
        </div >)
        : <></>}
    </>)
}
