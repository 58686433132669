import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './styles/Modals.css'
import { editDevice } from '../api/DeviceAPI';
import { SyncLoader, BarLoader } from 'react-spinners';

export default function AddEditDevice({ show, toggleModal, modalInEditMode, selectedRecord, deleteClicked,
  customers, toggleAlert, getDataFromDatabase, getCustomersLoading }) {

  const [serialNo, setserialNo] = useState("");
  const [name, setname] = useState("");
  const [customer, setcustomer] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (show && modalInEditMode) {
      populateFields()
    } else {
      clearFields()
    }
  }, [show]);

  function populateFields() {
    selectedRecord?.serialNo && setserialNo(selectedRecord.serialNo)
    selectedRecord?.name && setname(selectedRecord.name)
    selectedRecord?.customer?._id && setcustomer(selectedRecord.customer._id)
  }
  function clearFields() {
    setserialNo("")
    setname("")
    setcustomer("")
  }

  function onChange(event) {
    switch (event.target.name) {
      case "serialNo":
        setserialNo(event.target.value)
        break;
      case "name":
        setname(event.target.value)
        break;
      case "customer":
        event.target.value === 'none' ? setcustomer(null) : setcustomer(event.target.value)
        break;
      default:
        break;
    }
  }

  async function onSubmit() {
    //validation
    setisLoading(true)
    const props = { name, customer }
    const deviceId = selectedRecord._id
    const res = await editDevice(deviceId, props)
    let message = "An error occured"
    if (res) {
      message = "Device updated"
      toggleAlert(true, 2, message)
      handleClose()
      getDataFromDatabase(-1, true)
    } else {
      message = "Error updating device"
      toggleAlert(true, 0, message)
    }
    setisLoading(false)
  }

  function handleClose() {
    //close method
    toggleModal(false, 0)
  }
  return (
    <>
      {show ? (
        <div className='backdrop' onClick={handleClose}>
          <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
            <div className="modal-header" >
              <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Device"}</h3>
              <CloseIcon onClick={() => { handleClose() }} sx={{ cursor: 'pointer' }} />
            </div>
            {/* body container */}
            <form className='input-form'  >
              <div className="form-group-vertical">
                <label htmlFor="serialNo" className="col-form-label">Serial Number</label>
                <input disabled={true} value={serialNo} type="text" autoComplete='off' id='serialNo' placeholder={selectedRecord?.serialNumber} className="modal-input" name="serialNo" onChange={onChange} ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="name" className="col-form-label">Device Name</label>
                <input value={name} type="text" autoComplete='off' id='name' placeholder={selectedRecord?.name} className="modal-input" name="name" onChange={onChange} ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="customer" className="col-form-label">Customer</label>
                {getCustomersLoading ?
                  <div style={{ height: '27px', width: '198px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BarLoader color='#042754' width={'100%'} />
                  </div>
                  :
                  <select value={customer} className="modal-input" name="customer" id='customer' onChange={onChange} >
                    <option value="none" disabled={false}></option>
                    {customers.map((option) => {
                      return (<option key={option._id} value={option._id}>{option.name}</option>)
                    })}
                  </select>
                }
              </div>
            </form>
            <div className='submit-container' >
              <button className='submit-btn remove-btn' disabled={true}
                onClick={() => deleteClicked()}
                style={{
                  cursor: 'default',
                  opacity: 0
                }}>{"Delete"}</button>
              <button className='modal-submit-btn' onClick={() => { onSubmit() }} >
                {isLoading ?
                  <SyncLoader
                    size={6}
                    color="#ffffff"
                  /> : "Submit"
                }
              </button>
            </div>
          </div>

        </div >)
        : <></>}
    </>)
}
