export const dataColors =
{
    1: "#a2132f",
    2: "#4dbeed",
    3: '#808080',
    4: "#77ac2f",
    5: "#8e489b",
    6: "#edb020",
    7: "#d8531a",
    8: "#0072bc"
}