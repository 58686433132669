export const statDatasets =
{
    control: [
        { id: 'control-0', name: "Avg Control Temp", unitId: 0, type: 'ccm_avg_con_tmp' },
        { id: 'control-1', name: "Control Variance", unitId: 0, type: 'ccm_temp_diff' },
        { id: 'control-2', name: "Avg Cycle Period", unitId: 1, type: 'ccm_avg_cyc_per' },
        { id: 'control-3', name: "Avg Duty Cycle", unitId: 3, type: 'ccm_avg_cyc_duty' }
    ],
    defrost: [
        { id: 'defrost-0', name: "Defrost Time", unitId: 2, type: 'ccm_avg_def_time' },
        { id: 'defrost-1', name: "Max defrost Temp", unitId: 0, type: 'ccm_avg_max_def_tmp' },
        { id: 'defrost-2', name: "Avg defrost Temp", unitId: 0, type: '' }
    ],
    energy: [
        { id: 'energy-0', name: "On-state consumption", unitId: 4, type: 'ccm_avg_on_stat_pwr' },
        { id: 'energy-1', name: "Off-state consumption", unitId: 4, type: 'ccm_avg_off_stat_pwr' },
        { id: 'energy-2', name: "Avg Power", unitId: 4, type: 'ccm_avg_pwr' },
        { id: 'energy-3', name: "Total Daily Usage", unitId: 5, type: 'ccm_tot_eng_hrly' }
    ],
    ambient1:
        [{ id: 'ambient1-0', name: "Avg Temp", unitId: 0 },
        { id: 'ambient1-1', name: "Temp (Max / Min)", unitId: 0 },
        { id: 'ambient1-2', name: "Avg Humidity", unitId: 3 },
        { id: 'ambient1-3', name: "Humidity (Max / Min)", unitId: 3 }],
    ambient2:
        [{ id: 'ambient2-0', name: "Avg Ambient Temp", unitId: 0, type: 'ccm_avg_amb_temp' },
        { id: 'ambient2-1', name: "Max Temp", unitId: 0, type: '' },
        { id: 'ambient2-2', name: "Min Temp", unitId: 0, type: '' },
        { id: 'ambient2-3', name: "Avg Humidity", unitId: 3, type: 'ccm_avg_amb_hum' },
        { id: 'ambient2-4', name: "Max Humidity", unitId: 3, type: '' },
        { id: 'ambient2-5', name: "Min Humidity", unitId: 3, type: '' }]
}


export const unitValues = {
    0: { unit: '\u00b0C', title: 'Temperature' },
    1: { unit: 'sec', title: 'Time' },
    2: { unit: 'min', title: 'Time' },
    3: { unit: '%', title: 'Percent' },
    4: { unit: 'W', title: 'Power' },
    5: { unit: 'kWh', title: 'Cummulative Energy' },
}