import React from 'react'
import { dataColors } from '../../constants/colors'

export default function CCMGraph({
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    zoomGraph,
    graphViewBoxX,
    graphViewBoxY,
    yAxisValuesWidth,
    xAxisValuesHeight,
    renderHorizontalText,
    renderHorizontalLines,
    renderVerticalText,
    renderVerticalLines,
    graphContainerWidth,
    ccmGraphWidth,
    tempGraphHeight,
    renderTempGraph,
    preciseTracerValues,
    graphTranslateX,
    getGraphTransformation,
    showHoverAxis,
    hoverAxisCoordinates,
    scrollAmount,
    tempContainerHeight,
    renderPowerGraph,
    renderPowerFactorGraph,
    renderRelayGraph,
    relayGraphHeight,
    relayContainerHeight,
    yAxisPowerFactorValuesWidth
}) {

    return (
        <svg id='svg-container-canvas'
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => handleMouseUp()}
            onWheel={zoomGraph}
            style={{ width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
            viewBox={'0 0 ' + (graphViewBoxX) + ' ' + (graphViewBoxY)}>
            {/* Temp text and Y values */}
            <g id='hor-text-container' transform={'translate(' + (yAxisValuesWidth - 9) + ', ' + (30) + ')'}>
                <text style={{ userSelect: 'none' }} x={-tempGraphHeight / 2} y={-66} fontSize={21} transform='rotate(-90)' textAnchor="middle" width={"100%"}>Temp</text>
                {renderHorizontalText("temp").map((line, index) => {
                    return (
                        <g key={index}>
                            <text style={{ userSelect: 'none' }} textAnchor='middle' fontWeight={600} y={line.y + 3} x={-24} fill="black">{(line.value) + '\u00b0C'}</text>
                        </g>
                    )
                })}
            </g>
            {/* Energy text and Y values */}
            <g id='hor-text-container' transform={'translate(' + (yAxisValuesWidth - 9) + ', ' + (tempContainerHeight + 30) + ')'}>
                <text style={{ userSelect: 'none' }} x={-tempGraphHeight / 2} y={-66} fontSize={21} transform='rotate(-90)' textAnchor="middle" width={"100%"}>Power</text>
                {renderHorizontalText("power").map((line, index) => {
                    return (
                        <g key={index}>
                            <text style={{ userSelect: 'none' }} textAnchor='middle' fontWeight={600} y={line.y + 3} x={-24} fill="black">{(line.value) + 'W'}</text>
                        </g>
                    )
                })}
            </g>
            <g id='hor-text-container' transform={'translate(' + (graphContainerWidth - yAxisPowerFactorValuesWidth - 9) + ', ' + (tempContainerHeight + 30) + ')'}>
                <text style={{ userSelect: 'none' }} x={-tempGraphHeight / 2} y={60} fontSize={21} transform='rotate(-90)' textAnchor="middle" width={"100%"}>Power Factor</text>
                {renderHorizontalText("powerFactor").map((line, index) => {
                    return (
                        <g key={index}>
                            <text style={{ userSelect: 'none' }} textAnchor='middle' fontWeight={600} y={line.y + 3} x={24} fill="black">{(line.value)}</text>
                        </g>
                    )
                })}
            </g>
            <svg id='svg-temp-container'
                y={0}
                width={graphContainerWidth} height={tempContainerHeight}
                viewBox={'0 0 ' + (graphContainerWidth) + ' ' + (tempContainerHeight)} >
                <g transform={'translate(' + yAxisValuesWidth + ', ' + (30) + ')'}>
                    {renderHorizontalLines("temp").map((line, index) => {
                        return (
                            <g key={index}>
                                <line y1={line.y} x1="0" y2={line.y} x2={ccmGraphWidth} stroke='#00000033' strokeWidth={1} />
                            </g>
                        )
                    })}
                </g>
                <g transform={'translate(' + yAxisValuesWidth + ' ' + (xAxisValuesHeight) + ')'}>
                    <svg id='svg-axis-container'
                        width={ccmGraphWidth} height={tempGraphHeight}
                        viewBox={'0 0 ' + (ccmGraphWidth) + ' ' + (tempGraphHeight)}>
                        <g id='svg-axis-container-group' transform={getGraphTransformation()}>
                            <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                            {renderVerticalLines().map((line, index) => {
                                return (
                                    <line key={index} x1={line.x} y1="0" x2={line.x} y2={tempGraphHeight} stroke='#00000033' strokeWidth={100 / scrollAmount} />
                                )
                            })}
                            {renderTempGraph()}
                            {showHoverAxis &&
                                <line id='tracer-line-top' x1={hoverAxisCoordinates.x} x2={hoverAxisCoordinates.x} y1={0} y2={tempGraphHeight} stroke='red' strokeWidth={100 / scrollAmount}></line>
                            }
                            {Object.keys(preciseTracerValues.current).map((key) => {
                                if (key.includes("channel") && preciseTracerValues.current[key]?.x && preciseTracerValues.current[key]?.y) {
                                    return (
                                        <line
                                            className='precise-tracer-point-line'
                                            key={key}
                                            x1={preciseTracerValues.current[key]?.x}
                                            x2={preciseTracerValues.current[key]?.x}
                                            y1={preciseTracerValues.current[key]?.y}
                                            y2={preciseTracerValues.current[key]?.y}
                                            stroke={dataColors[key.slice(-1)]} strokeWidth={9}
                                            strokeLinecap='round'
                                            vectorEffect={'non-scaling-stroke'}>
                                        </line>
                                    )
                                }
                            })}
                        </g>
                        <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='#042855' />
                    </svg>
                </g>
                <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
            </svg>
            <svg id='svg-relay-container'
                y={tempContainerHeight} width={graphContainerWidth} height={relayContainerHeight}
                viewBox={'0 0 ' + (graphContainerWidth) + ' ' + (relayContainerHeight)}>
                <g transform={'translate(' + yAxisValuesWidth + ')'}>
                    <svg id='svg-axis-container'
                        width={ccmGraphWidth} height={relayGraphHeight}
                        viewBox={'0 0 ' + (ccmGraphWidth) + ' ' + (relayGraphHeight)}>
                        <g transform={getGraphTransformation()}>
                            <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                            {renderVerticalLines().map((line, index) => {
                                return (
                                    <line key={index} x1={line.x} y1="0" x2={line.x} y2={relayGraphHeight} stroke='#00000033' strokeWidth={100 / scrollAmount} />
                                )
                            })}
                            {renderRelayGraph()}
                            {showHoverAxis &&
                                <line id='tracer-line-bottom' x1={hoverAxisCoordinates.x} x2={hoverAxisCoordinates.x} y1={0} y2={relayGraphHeight} stroke='red' strokeWidth={100 / scrollAmount}></line>
                            }
                        </g>
                    </svg>
                </g>
                <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
            </svg>
            <svg id='svg-energy-container'
                y={tempContainerHeight + relayContainerHeight}
                width={graphContainerWidth} height={tempContainerHeight}
                viewBox={'0 0 ' + (graphContainerWidth) + ' ' + (tempContainerHeight)} >
                <g transform={'translate(' + yAxisValuesWidth + ', -' + (xAxisValuesHeight - 30) + ')'}>
                    {renderHorizontalLines("power").map((line, index) => {
                        return (
                            <g key={index}>
                                <line y1={line.y} x1="0" y2={line.y} x2={ccmGraphWidth} stroke='#00000033' strokeWidth={1} />
                            </g>
                        )
                    })}
                </g>
                <g transform={'translate(' + yAxisValuesWidth + ')'}>
                    <svg id='svg-axis-container-energy'
                        width={ccmGraphWidth} height={tempGraphHeight}
                        viewBox={'0 0 ' + (ccmGraphWidth) + ' ' + (tempGraphHeight)}>
                        <g id='svg-axis-container-group-energy' transform={getGraphTransformation()}>
                            <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                            {renderVerticalLines().map((line, index) => {
                                return (
                                    <line key={index} x1={line.x} y1="0" x2={line.x} y2={tempGraphHeight} stroke='#00000033' strokeWidth={100 / scrollAmount} />
                                )
                            })}
                            {renderPowerGraph()}
                            {renderPowerFactorGraph()}
                            {showHoverAxis &&
                                <line id='tracer-line-top' x1={hoverAxisCoordinates.x} x2={hoverAxisCoordinates.x} y1={0} y2={tempGraphHeight} stroke='red' strokeWidth={100 / scrollAmount}></line>
                            }
                            {Object.keys(preciseTracerValues.current).map((key) => {
                                if ((key.includes("power") || key.includes("pwrFact")) && preciseTracerValues.current[key]?.x && preciseTracerValues.current[key]?.y) {
                                    return (
                                        <line
                                            className='precise-tracer-point-line'
                                            key={key}
                                            x1={preciseTracerValues.current[key]?.x}
                                            x2={preciseTracerValues.current[key]?.x}
                                            y1={preciseTracerValues.current[key]?.y}
                                            y2={preciseTracerValues.current[key]?.y}
                                            stroke={dataColors[key.slice(-1)]} strokeWidth={9}
                                            strokeLinecap='round'
                                            vectorEffect={'non-scaling-stroke'}>
                                        </line>
                                    )
                                }
                            })}
                        </g>
                        <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='#042855' />
                    </svg>
                </g>
                <g transform={'translate(' + (yAxisValuesWidth - 25) + ',' + tempGraphHeight + ')'}>
                    <svg id='svg-y-axis-title-container'
                        width={ccmGraphWidth + 25} height={xAxisValuesHeight}
                        viewBox={'0 0 ' + (ccmGraphWidth + 25) + ' ' + (xAxisValuesHeight)}>
                        <rect width={'100%'} height="100%" x="0" y="0" fill="none" stroke='transparent' />
                        {renderVerticalText().map((line, index) => {
                            return (
                                <text style={{ userSelect: 'none' }} key={index} fontSize={15} fontWeight={600} textAnchor='middle' x={line.x + 25} y={24} fill="black">{line.time}</text>
                            )
                        })}
                        {showHoverAxis && <svg id='tracer-time-container' x={(hoverAxisCoordinates.x) * (scrollAmount / 100) - graphTranslateX} y={0} width={48} height={xAxisValuesHeight} viewBox={'0 0 48 ' + xAxisValuesHeight} >
                            <rect rx={6} ry={6} width={46} height={xAxisValuesHeight - 4} x="1" y="3" fill="white" stroke='red' />
                            <text style={{ userSelect: 'none' }} fontSize={15} fontWeight={600} textAnchor='middle' x={24} y={24} fill="red">{(hoverAxisCoordinates.time)}</text>
                        </svg>}
                    </svg>
                </g>
                <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
            </svg>
            <rect width="100%" height="100%" x="0" y="0" fill="none" stroke='transparent' />
        </svg>
    )
}


