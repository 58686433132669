import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './styles/Modals.css'
import { SyncLoader } from 'react-spinners';
import { uploadData } from 'aws-amplify/storage';
import { getServiceProfile, updateSim } from '../api/SimsAPI';
import { simStateName } from '../constants/dataVars';


export default function AddEditSim({ show, toggleModal, toggleAlert, selectedRecord }) {

  const [serialNumber, setSerialNumber] = useState()
  const [state, setState] = useState()
  const [serviceProfile, setServiceProfile] = useState()
  const [serviceProfileOptions, setServiceProfileOptions] = useState()
  const [isLoading, setisLoading] = useState(false);

  const getServiceProfileOptions = async () => {
    const allProfiles = await getServiceProfile()
    if (allProfiles) {
      setServiceProfileOptions(allProfiles)
    }
  }

  useEffect(() => {
    if (show) {
      populateFields()
    } else {
      clearFields()
    }
  }, [show]);

  const populateFields = () => {
    getServiceProfileOptions()
    setSerialNumber(selectedRecord.serialNumber)
    setState(selectedRecord.state)
    setServiceProfile(selectedRecord.serviceProfile)
  }

  function clearFields() {
    setState('')
    setServiceProfile('')
  }

  function onChange(event) {
    switch (event.target.name) {
      case "state":
        setState(event.target.value)
        break;
      case "serviceProfile":
        setServiceProfile(event.target.value)
        break;
      default:
        break;
    }
  }

  async function onSubmit() {
    //validation
    try {
      const res = await updateSim(selectedRecord.iccid, {
        serviceProfile,
        state
      })
      toggleAlert(true, 2, "Sim Updated Succesfully")
    } catch (err) {
      const data = JSON.parse(err.response.body);
      toggleAlert(true, 0, data.message)
    } finally {
      toggleModal(false)
      setisLoading(false)
    }
  }

  function handleClose() {
    //close method
    toggleModal(false)
  }

  // serial number 
  // state -- enums
  // service profile -- Informwation from the database -- GET gdsp/serviceProfile

  return (
    <>
      {show ? (
        <div className='backdrop' onClick={handleClose}>
          <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
            <div className="modal-header" >
              <h3 className='modal-title' >{"Edit Sim"}</h3>
              <CloseIcon onClick={() => { handleClose() }} sx={{ cursor: 'pointer' }} />
            </div>
            {/* body container */}
            <form className='input-form'  >
              <div className="form-group-vertical">
                <label htmlFor="serialNumber" className="col-form-label">Serial Number:</label>
                <input value={serialNumber} type="text" autoComplete='off' id='serialNumber' placeholder='' className="modal-input" name="serialNumber" disabled ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="state" className="col-form-label">State: </label>
                <select value={state} type="text" autoComplete='off' id='state' placeholder='' className="modal-input" name="state" onChange={onChange} >
                  <option value={''}></option>
                  {Object.keys(simStateName)?.map((state, index) => (
                    <option key={index} value={state}>{simStateName[state]}</option>
                  ))}
                </select>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="description" className="col-form-label">Service Profile</label>
                <select value={serviceProfile} type="text" autoComplete='off' id='serviceProfile' placeholder='' className="modal-input" name="serviceProfile" onChange={onChange} >
                  <option value={''}></option>
                  {serviceProfileOptions?.map((profile, index) => (
                    <option key={index} value={profile?.name}>{profile?.name}</option>
                  ))}
                </select>
              </div>
            </form>
            <div className='submit-container'>
              <button className='modal-submit-btn' onClick={() => { onSubmit() }} >
                {isLoading ?
                  <SyncLoader
                    size={6}
                    color="#ffffff"
                  /> : "Submit"
                }
              </button>
            </div>
          </div>
        </div >)
        : <></>}
    </>)
}
