import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Callback from './views/Callback';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import Logout from './views/Logout';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Page404 from './views/Page404';
import { Amplify } from "aws-amplify"
import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import ControlSettings from './components/ControlSettings';

import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify/utils';

Amplify.configure({
  API: {
    REST: {
      "fl2-rest-api-gateway": {
        endpoint: process.env.REACT_APP_API_URL,
        region: "eu-west-1"
      }
    }
  },
  Auth: {
    Cognito: {
      userPoolId: "eu-west-1_SAMBFagTb",
      userPoolClientId: "3ovou4rarq8648d0kgvu5h3ad6",
      identityPoolId: "eu-west-1:90ef17ff-8b12-43f5-931e-530ad18fc8f3",
      loginWith: {
        oauth: {
          domain: 'staging-colcab.auth.eu-west-1.amazoncognito.com',
          scopes: ['openid', 'email', 'profile', 'api.fridgelink.com/*'],
          redirectSignIn: [
            'http://localhost:3000/callback',
            'https://staging.fridgelink.com/callback',
            'https://new.fridgelink.com/callback',
          ],
          redirectSignOut: [
            'http://localhost:3000/logout',
            'https://staging.fridgelink.com/logout',
            'https://new.fridgelink.com/logout',
          ],
          responseType: 'code'
        }
      }
    }
  },
  Storage: {
    S3: {
      buckets: {
        'firmware': {
          bucketName: 'dev-fridgelink2-firmware',
          region: 'eu-west-1'
        }
      }
    }
  }
}, {
  API: {
    REST: {
      headers: async () => {
        const session = await fetchAuthSession();
        return { Authorization: session.tokens.accessToken };
      }
    }
  }
});

const cicdTest6 = true;

Hub.listen('pubsub', (data) => {
  const { payload } = data;
  if (payload.event === CONNECTION_STATE_CHANGE) {
    const connectionState = payload.data.connectionState;
    // console.log(payload);
  }
});

async function handleSignOut(navigate) {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard
      handleSignOut={handleSignOut} />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  // {
  //   path: "/forgot-password",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/reset-password",
  //   element: <ResetPassword />,
  // },
  {
    path: "*",
    element: <Page404 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={router} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
