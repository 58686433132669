import React from 'react'
import './styles/DataTable.css'
import TableRow from './TableRow.js'
import logoIcon from '../../assets/ColcabLogoNoText.png'
import { columnData, columnSearchableKeys } from '../../constants/dataVars.js'

export default function DataTable({
  tableLoading,
  selectedMenuIndex,
  editClicked,
  selectedListData,
  selectedRecord,
  recordSelected,
  searchTerm,
  sortBy,
  orderBy,
  filters,
  setShowControlSettings,
  setSelectedSettingsRecord
}) {

  // Filter the data based on the search value
  const filteredData = selectedListData?.filter((item) => {

    // Determine Item Status
    const itemStatus = (item.connected === false ? (item.pwrStat === 1 ? 2 : 0) : 1)

    // Check if the item status is active in filters
    if (!filters[itemStatus]) return false

    // return true if there is no search value
    if (searchTerm === '') return true

    const currentSearchableKeys = columnSearchableKeys[selectedMenuIndex]
    return currentSearchableKeys.some((key) => {
      const value = Array.isArray(key)
        ? item[key[0]]?.[key[1]] // nested key value
        : item[key]; // single key value
      return (value?.toLowerCase().includes(searchTerm.toLowerCase()))
    })
  }).sort((a, b) => { // Sort Data
    if (sortBy !== 'none') {
      const getValue = (obj, keyPath) => keyPath.split('.').reduce((acc, key) => acc?.[key], obj);

      const aValue = String(getValue(a, sortBy));
      const bValue = String(getValue(b, sortBy));

      return orderBy === 'asc'
        ? aValue?.localeCompare(bValue)
        : bValue?.localeCompare(aValue);
    } else {
      return 0;
    }
  })

  const checkSelectedRecord = (record) => {

    // determine if the id is iccid for sims 
    if (record.iccid) {
      if (record.iccid === selectedRecord.iccid) {
        return true
      } else {
        return false
      }
    } else {
      if (record._id === selectedRecord._id) {
        return true
      } else {
        return false
      }
    }

  }

  return (
    <div className='data-table-body'>
      <div className='table-header'>
        {columnData[selectedMenuIndex]?.map((columnObj, index) => {
          return (
            <div key={index} style={{ flex: columnObj.widthIndex }}
              className={`header-column ${columnObj.columnKey === 'status' && 'status-header-column'}`}>
              <h4>{columnObj.header}</h4>
            </div>
          )
        })}
      </div>
      <div className='table-body'>
        {tableLoading ?
          <div className='loader-container'>
            <img src={logoIcon} alt='Logo' id='loader-logo' ></img>
          </div>
          :
          <>
            {filteredData?.map((record, index) => {
              let isSelected = checkSelectedRecord(record)
              return (
                <TableRow
                  key={index}
                  selectedMenuIndex={selectedMenuIndex}
                  columnData={columnData[selectedMenuIndex]}
                  isSelected={isSelected}
                  recordSelected={recordSelected}
                  record={record}
                  editClicked={editClicked}
                  setShowControlSettings={setShowControlSettings}
                  setSelectedSettingsRecord={setSelectedSettingsRecord}
                />)
            })}
          </>}
      </div>
    </div>
  )
}
