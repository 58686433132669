import { React, useState } from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ProfileDropdown from './ProfileDropdown';
import logoIcon from '../../assets/ColcabLogoNoText.png'
import './styles/SideNav.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { menuItems } from '../../constants/dataVars';
import { ReactComponent as FridgelinkLogo } from '../../assets/FridgeLink_White.svg'

export default function SideNav({ selectedMenuIndex, changeSelectedMenuItem, navigate, handleSignOut, profileName, setShowControlSettings }) {
  const [profileDropdownOpen, setprofileDropdownOpen] = useState(false);

  const handleProfileDropDown = () => {
    setprofileDropdownOpen(!profileDropdownOpen);
  };

  const handleLogout = () => {
    handleSignOut(navigate)
  }

  const handleSidebarClick = (index) => {
    setShowControlSettings(false)
    changeSelectedMenuItem(index)
  }

  return (
    <div className='side-nav'>
      <div className='side-nav-body'>
        <div className='header-container'>
          <FridgelinkLogo />
        </div>
        {menuItems.map((item, index) => {
          const selected = index === selectedMenuIndex
          return (
            <div key={index} onClick={() => handleSidebarClick(index)} className='side-nav-menu-item'
              style={{ backgroundColor: selected ? "#ffffff43" : "transparent" }}>
              <div className='menu-left-container'>
                {item.icon}
                <h4>{item.title}</h4>
              </div>
              {selected && <KeyboardArrowRightIcon sx={{ color: 'white' }} />}
            </div>
          )
        })}
      </div>
      <div className='side-nav-footer'>
        <div className='option-button'>
          <PersonOutlineIcon sx={{ color: '#042754' }} onClick={() => setprofileDropdownOpen(prev => !prev)} />
          {profileDropdownOpen &&
            <ProfileDropdown
              handleProfileDropDown={handleProfileDropDown}
              handleLogout={handleLogout} />
          }
        </div>
        <p className='profile-name'>
          {profileName}
        </p>
      </div>
    </div>
  )
}
