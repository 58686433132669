import React from 'react'
import './styles/OverviewCard.css'

export default function CollapsedOverviewCard({ selectedMenuIndex, channelObj }) {

    const color = selectedMenuIndex === 2 ? (channelObj?.chanen === 0 ? "lightgray" : 'black') : (channelObj?.cMode === 0 ? "lightgray" : 'black')
    const channelName = channelObj?.name ?? '';
    const CASEMmodes = ['-', "On-Off", "Proportional"]

    return (
        <div className='data-display-card-collapsed' >
            <div className='data-display-start'>
                <h4 style={{ color }}>{channelName}</h4>
                <div />
            </div>
            <div className='data-display-end'>
                <div className='row'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p style={{ color }}>{selectedMenuIndex === 2 ? "Threshold Temp:" : "Temp:"}</p>
                    </div>
                    <p style={{ color }}>{(channelObj?.temp) ? (channelObj?.temp?.toFixed(2)) + '\u00b0C' : 'N/A'}</p>
                </div>
                <div className='row'>
                    <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '50%' }}>
                        <p style={{ color, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{selectedMenuIndex === 2 ? "Control Type:" : "Rel State:"}</p>
                    </div>
                    <p style={{ color, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{channelObj?.relState === 1 ? 'ON' : "OFF"}</p>
                </div>
            </div>
        </div>
    )
}
