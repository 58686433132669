import { useEffect, useState } from 'react'
import './styles/ControlSettings.css'
import TableRow from './ControlSettings/TableRow.js'
import FirmwareTableRow from './ControlSettings/FirmwareTableRow.js'
import Logs from './ControlSettings/Logs.js'
import { pubsubPublishSettings } from '../mqtt/Functions'
import { getSingletonPubSub } from '../mqtt/MyPubSub'
import ConfirmUpdateModal from './ControlSettings/ConfirmUpdateModal.js'
import { getDeviceModules } from '../api/DeviceAPI.js'
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { fetchAuthSession } from "aws-amplify/auth";
import { SyncLoader } from 'react-spinners'

// const command = new GetObjectCommand(getObjectParams);
// const url = await getSignedUrl(client, command, { expiresIn: 3600 });

const ControlSettings = ({ record, toggleAlert }) => {
  const deviceId = record.thingName
  const serial = record.serialNumber
  const [firmwareTableRowLoader, setFirmwareTableRowLoader] = useState(false)
  const [deviceModules, setDeviceModules] = useState([])
  const [mqttMessages, setMqttMessages] = useState([])
  const [newSettingsValues, setNewSettingsValues] = useState({})
  const [newFirmwareValues, setNewFirmwareValues] = useState({})
  const [updateType, setUpdateType] = useState()
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [settingsLog, setSettingsLog] = useState(null);
  const [logStorage, setLogStorage] = useState([])
  const [firmwareLoaders, setFirmwareLoaders] = useState({
    firmVTelem: true,
    firmVCtrl: true
  })
  const [firmwareLoadersColors, setFirmwareLoadersColors] = useState({
    firmVTelem: 'gray',
    firmVCtrl: 'gray'
  })
  const [firmwareValues, setFirmwareValues] = useState({
    firmVTelem: null,
    firmVCtrl: null
  })
  const settings = {
    ctlSp: { alias: 'Control Setpoint', unit: '°C' },
    ctlDiff: { alias: 'Control Differential', unit: '°C' },
    defInt: { alias: 'Defrost Interval', unit: ' hrs' },
    defTime: { alias: 'Defrost Time', unit: ' min' },
    dataInt: { alias: 'Data Period', unit: ' min' },
  }
  const [settingsValues, setSettingsValues] = useState({
    ctlSp: null,
    ctlDiff: null,
    defInt: null,
    defTime: null,
    dataInt: null,
  })
  const [settingsLoaders, setSettingsLoaders] = useState({
    ctlSp: true,
    ctlDiff: true,
    defInt: true,
    defTime: true,
    dataInt: true,
  })
  const [settingsLoadersColors, setSettingsLoadersColors] = useState({
    ctlSp: 'gray',
    ctlDiff: 'gray',
    defInt: 'gray',
    defTime: 'gray',
    dataInt: 'gray',
  })

  const extractLog = (inputString) => {
    try {
      const newLogs = JSON.parse(JSON.stringify(logStorage))

      // Parse the input string into a JavaScript object
      if (inputString?.metadata?.topic?.endsWith('/state')) {
        const parsedObject = JSON.parse(inputString.log);
        if (parsedObject.device || parsedObject.settings) {
          if (parsedObject.telemetry) {
            delete parsedObject.telemetry
          }

          newLogs.push({
            title: 'Payload Received',
            timestamp: inputString.timestamp,
            topic: inputString.metadata.topic,
            data: parsedObject,
            iconIndex: 3,
            _id: inputString._id
          })
        }
      } else if (inputString?.metadata?.topic?.endsWith('/cmd')) {

        const parsedObject = JSON.parse(inputString.log);
        if (parsedObject.settings.ctrlOtaUrl) {
          parsedObject.settings.ctrlOtaUrl = 'Omitted'
        }
        if (parsedObject.settings.telemOtaUrl) {
          parsedObject.settings.telemOtaUrl = 'Omitted'
        }
        newLogs.push({
          title: 'Payload Sent',
          timestamp: inputString.timestamp,
          topic: inputString.metadata.topic,
          data: parsedObject,
          iconIndex: 2,
          _id: inputString._id

        })
      } else if (inputString?.metadata.topic.startsWith('$aws/events/presence')) {
        // const parsedObject = JSON.parse(inputString.log);
        newLogs.push({
          title: 'State Changed',
          timestamp: inputString.timestamp,
          topic: inputString.metadata.topic,
          data: { value: inputString.log },
          iconIndex: inputString.log === 'connected' ? 1 : 0,
          _id: inputString._id

        })
      }
      setLogStorage(newLogs)

    } catch (error) {
      console.error("Error:", error.message);
      return null;
    }
  }

  const handleMQTT = (message) => {
    if (message.log) {
      setSettingsLog(extractLog(message))
    } else {
      if (newSettingsValues[message.measureName] === message.value || settingsValues[message.measureName] === null) {
        setSettingsValues(prev => ({ ...prev, [message.measureName]: message.value }))
        setSettingsLoaders(prev => ({ ...prev, [message.measureName]: false }))
        const copyNewSettingsValues = { ...newSettingsValues }
        delete copyNewSettingsValues[message.measureName]
        setNewSettingsValues(copyNewSettingsValues)
      }
    }
  }

  useEffect(() => {
    if (mqttMessages.length > 0) {
      for (let i = 0; i < mqttMessages.length; i++) {
        handleMQTT(mqttMessages[i])
      }
    }
  }, [mqttMessages])

  const getCurrentModules = async () => {
    setFirmwareTableRowLoader(true)
    const allModules = await getDeviceModules(record._id)
    if (allModules) {
      setDeviceModules(allModules)
      setFirmwareTableRowLoader(false)
    }
  }

  useEffect(() => {
    let ref = null
    const getCurrentSetting = async () => {
      const pubsub = await getSingletonPubSub()
      ref = pubsub.subscribe({
        topics:
          [
            `device/ccm/${deviceId}/settings/${'ctlSp'}`,
            `device/ccm/${deviceId}/settings/${'ctlDiff'}`,
            `device/ccm/${deviceId}/settings/${'defInt'}`,
            `device/ccm/${deviceId}/settings/${'defTime'}`,
            `device/ccm/${deviceId}/settings/${'dataInt'}`,
            `device/ccm/${deviceId}/log`
          ]
      }).subscribe({
        next: (data) => {
          setMqttMessages(prev => [...prev, data].slice(-10))
        },
        error: (error) => console.error(error),
        complete: () => console.log("done")
      })
    }

    getCurrentModules()
    getCurrentSetting()
  }, [])

  // Show Confirm Update Modal When Update Is Clicked
  const handleUpdateClick = (type) => {

    setUpdateType(type)
    setShowUpdateModal(true)
  }

  const updateLoaders = (loadState) => {
    const copySettingsLoaders = JSON.parse(JSON.stringify(settingsLoaders))
    const copySettingsLoadersColors = JSON.parse(JSON.stringify(settingsLoadersColors))

    Object.keys(settingsLoaders).forEach((key) => {
      if (loadState === true && key in newSettingsValues) {
        copySettingsLoaders[key] = true
        copySettingsLoadersColors[key] = 'green'
      } else {
        copySettingsLoaders[key] = false
      }
    })
    setSettingsLoaders(copySettingsLoaders)
    setSettingsLoadersColors(copySettingsLoadersColors)
  }

  const generateURL = async (bucket, key) => {
    const authSession = await fetchAuthSession();
    const client = new S3Client({
      region: 'eu-west-1',
      credentials: authSession.credentials,
    });

    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
    });

    return await getSignedUrl(client, command, { expiresIn: 3600 });

  }

  // Functionality for updating the settings
  const updateAction = async () => {
    if (updateType === 'setting') {
      if (Object.keys(newSettingsValues).length > 0) {
        const payload = { settings: { ...newSettingsValues } }
        updateLoaders(true)
        await pubsubPublishSettings('ccm', deviceId, payload)
      } else {
        console.log('Payload Is Empty')
      }
    } else if (updateType === 'firmware') {

      // setFirmwareFlag(true)
      const pubsub = await getSingletonPubSub()
      if (newFirmwareValues.firmVCtrl) {
        setFirmwareLoaders(prev => ({ ...prev, firmVCtrl: true }))
        setFirmwareLoadersColors(prev => ({ ...prev, firmVCtrl: 'green' }))
        const url = await generateURL(newFirmwareValues.firmVCtrl.bucket, newFirmwareValues.firmVCtrl.key)
        await pubsub.publish({
          topics: `device/ccm/${deviceId}/cmd`, message: {
            settings: {
              ctrlOtaUrl: url,
              otaVersion: newFirmwareValues.firmVCtrl.versionName
            }
          }
        })
      }

      if (newFirmwareValues.firmVTelem) {
        setFirmwareLoaders(prev => ({ ...prev, firmVTelem: true }))
        setFirmwareLoadersColors(prev => ({ ...prev, firmVTelem: 'green' }))
        const url = await generateURL(newFirmwareValues.firmVTelem.bucket, newFirmwareValues.firmVTelem.key)
        await pubsub.publish({
          topics: `device/ccm/${deviceId}/cmd`, message: {
            settings: {
              telemOtaUrl: url,
              otaVersion: newFirmwareValues.firmVTelem.versionName
            }
          }
        })
      }
    }
    // setFirmwareFlag(false)
    setShowUpdateModal(false)
  }

  return (
    <div className='control-settings-container'>
      {showUpdateModal &&
        <ConfirmUpdateModal
          updateCode={serial}
          setShowUpdateModal={setShowUpdateModal}
          updateAction={() => { updateAction() }}
          toggleAlert={toggleAlert}
        />
      }
      <div className='table-container'>
        {/* SETTINGS TABLE */}
        <div className='table'>
          <h1>{serial + ' Control Settings'} </h1>

          {/* Field Row */}
          <div className='row'>
            <h4 className='column'>Name</h4>
            <h4 className='column'>Current Setting </h4>
            <h4 className='column'>New Setting </h4>
            <h4 className='column'>Update Status </h4>
          </div>

          {/* Data Row */}
          {Object.keys(settings).map((keyValue, index) => (
            <TableRow
              key={index}
              keyValue={keyValue}
              settings={settings}
              settingsValues={settingsValues}
              newSettingsValues={newSettingsValues}
              setNewSettingsValues={setNewSettingsValues}
              settingsLoaders={settingsLoaders}
              setSettingsLoaders={setSettingsLoaders}
              settingsLoadersColors={settingsLoadersColors}
            />
          ))}
          <button onClick={() => { handleUpdateClick('setting') }}> Update </button>
        </div>


        {/* FIRMWARE OVER THE AIR UPDATE TABLE */}
        <div className='table' target={'firmware'}>

          {!firmwareTableRowLoader ? (
            <div>
              <h1>Remote Firmware Update </h1>

              {/* Field Row */}
              <div className='row'>
                <h4 className='column'>Type</h4>
                <h4 className='column'>Firmware Version</h4>
                <h4 className='column'> New Version </h4>
                <h4 className='column'>Update Status </h4>
              </div>
              {deviceModules.map((module, index) => (
                <FirmwareTableRow
                  key={index}
                  module={module}
                  setFirmwareLoaders={setFirmwareLoaders}
                  firmwareLoaders={firmwareLoaders}
                  firmwareValues={firmwareValues}
                  setFirmwareValues={setFirmwareValues}
                  setNewFirmwareValues={setNewFirmwareValues}
                  newFirmwareValues={newFirmwareValues}
                  setFirmwareLoadersColors={setFirmwareLoadersColors}
                  firmwareLoadersColors={firmwareLoadersColors}
                />
              ))}
              <button onClick={() => { handleUpdateClick('firmware') }}> Update </button>
            </div>
          ) : (
            <div
              style={{
                height: '400px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SyncLoader color='#0951b9' />
            </div>
          )}
        </div>
      </div>
      <Logs
        logStorage={logStorage}
        setLogStorage={setLogStorage}
      />
    </div>
  )
}

export default ControlSettings
