import React from 'react'
import { ReactComponent as FridgelinkLogo } from '../assets/FridgeLink_Final.svg'
import '../components/styles/LogoAnimation.css'
import './styles/LoadingPage.css'

const LoadingPage = () => {
    return (
        <div className='loading-container'>
            <div className='logo-container'>
                <FridgelinkLogo />
            </div>
        </div>
    )
}

export default LoadingPage