import React, { useState } from 'react'
import './styles/StatisticsContainer.css'
import StatisticsGraphContainer from '../GraphComponent/StatisticsGraphContainer'
import { statDatasets, unitValues } from '../../constants/statVars';

export default function StatisticsContainer({ deviceId, serialNumber, toggleAlert,
    numGraphsDisplayed,
    finalStartDateA,
    finalEndDateA,
    finalGranularityA,
    datasetDisplayDetailsA,
    datasetsA,
    graphComponentValuesA,
    yAxisConstantsStateA,
    claimedDatasetColorsA,
    unitIdInUseA,
    finalStartDateB,
    finalEndDateB,
    finalGranularityB,
    datasetDisplayDetailsB,
    datasetsB,
    graphComponentValuesB,
    yAxisConstantsStateB,
    claimedDatasetColorsB,
    unitIdInUseB,
    setnumGraphsDisplayed,
    setfinalStartDateA,
    setfinalEndDateA,
    setfinalGranularityA,
    setdatasetDisplayDetailsA,
    setdatasetsA,
    setgraphComponentValuesA,
    setyAxisConstantsStateA,
    setclaimedDatasetColorsA,
    setunitIdInUseA,
    setfinalStartDateB,
    setfinalEndDateB,
    setfinalGranularityB,
    setdatasetDisplayDetailsB,
    setdatasetsB,
    setgraphComponentValuesB,
    setyAxisConstantsStateB,
    setclaimedDatasetColorsB,
    setunitIdInUseB,
    syncLocked,
    setsyncLocked,
    syncedZoom,
    setsyncedZoom,
    syncedTranslateX,
    setsyncedTranslateX
}) {

    return (
        <div className='statistics-body-container' numgraphsdisplayed={numGraphsDisplayed}>
            <div className='statistics-start-container'>
                <div className='stat-card small-card'>
                    <h4>Control Statistics</h4>
                    {statDatasets.control.map((data, index) => {
                        return (
                            <div key={index} className='row'>
                                <p>{data.name}</p>
                                <p>{Math.round(Math.random() * 12) + unitValues[data.unitId].unit}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='stat-card small-card'>
                    <h4>Defrost Statistics</h4>
                    {statDatasets.defrost.map((data, index) => {
                        return (
                            <div key={index} className='row'>
                                <p>{data.name}</p>
                                <p>{Math.round(Math.random() * 15) + unitValues[data.unitId].unit}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='stat-card small-card'>
                    <h4>Energy Statistics</h4>
                    {statDatasets.energy.map((data, index) => {
                        return (
                            <div key={index} className='row'>
                                <p>{data.name}</p>
                                <p>{Math.round(Math.random() * 19) + unitValues[data.unitId].unit}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='stat-card big-card'>
                    <h4>Ambient Conditions</h4>
                    {statDatasets['ambient' + numGraphsDisplayed].map((data, index) => {
                        return (
                            <div key={index} className='row'>
                                <p>{data.name}</p>
                                <p>{Math.round(Math.random() * 22) + unitValues[data.unitId].unit}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='statistics-end-container'>
                {/* Graph + Controls */}
                <StatisticsGraphContainer
                    chartIndex={0}
                    deviceId={deviceId}
                    numGraphsDisplayed={numGraphsDisplayed}
                    setnumGraphsDisplayed={setnumGraphsDisplayed}
                    toggleAlert={toggleAlert}
                    statDatasets={statDatasets}
                    serialNumber={serialNumber}
                    finalStartDate={finalStartDateA}
                    finalEndDate={finalEndDateA}
                    finalGranularity={finalGranularityA}
                    datasetDisplayDetails={datasetDisplayDetailsA}
                    datasets={datasetsA}
                    graphComponentValues={graphComponentValuesA}
                    yAxisConstantsState={yAxisConstantsStateA}
                    claimedDatasetColors={claimedDatasetColorsA}
                    unitIdInUse={unitIdInUseA}
                    setfinalStartDate={setfinalStartDateA}
                    setfinalEndDate={setfinalEndDateA}
                    setfinalGranularity={setfinalGranularityA}
                    setdatasetDisplayDetails={setdatasetDisplayDetailsA}
                    setdatasets={setdatasetsA}
                    setgraphComponentValues={setgraphComponentValuesA}
                    setyAxisConstantsState={setyAxisConstantsStateA}
                    setclaimedDatasetColors={setclaimedDatasetColorsA}
                    setunitIdInUse={setunitIdInUseA}
                    syncLocked={syncLocked}
                    setsyncLocked={setsyncLocked}
                    syncedZoom={syncedZoom}
                    setsyncedZoom={setsyncedZoom}
                    syncedTranslateX={syncedTranslateX}
                    setsyncedTranslateX={setsyncedTranslateX}
                />
                {numGraphsDisplayed === 2 &&
                    <StatisticsGraphContainer
                        chartIndex={1}
                        deviceId={deviceId}
                        numGraphsDisplayed={numGraphsDisplayed}
                        setnumGraphsDisplayed={setnumGraphsDisplayed}
                        toggleAlert={toggleAlert}
                        statDatasets={statDatasets}
                        finalStartDate={finalStartDateB}
                        finalEndDate={finalEndDateB}
                        finalGranularity={finalGranularityB}
                        datasetDisplayDetails={datasetDisplayDetailsB}
                        datasets={datasetsB}
                        graphComponentValues={graphComponentValuesB}
                        yAxisConstantsState={yAxisConstantsStateB}
                        claimedDatasetColors={claimedDatasetColorsB}
                        unitIdInUse={unitIdInUseB}
                        setfinalStartDate={setfinalStartDateB}
                        setfinalEndDate={setfinalEndDateB}
                        setfinalGranularity={setfinalGranularityB}
                        setdatasetDisplayDetails={setdatasetDisplayDetailsB}
                        setdatasets={setdatasetsB}
                        setgraphComponentValues={setgraphComponentValuesB}
                        setyAxisConstantsState={setyAxisConstantsStateB}
                        setclaimedDatasetColors={setclaimedDatasetColorsB}
                        setunitIdInUse={setunitIdInUseB}
                        syncLocked={syncLocked}
                        setsyncLocked={setsyncLocked}
                        syncedZoom={syncedZoom}
                        setsyncedZoom={setsyncedZoom}
                        syncedTranslateX={syncedTranslateX}
                        setsyncedTranslateX={setsyncedTranslateX} />}
            </div>
        </div>
    )
}
