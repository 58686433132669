import React, { useEffect, useState } from 'react'
import { getCurrentUser, signInWithRedirect } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import LoadingPage from './LoadingPage'

const protectedRoute = (Comp, route = '/login') => (props) => {
    const [isVisible, setIsVisible] = useState(true)
    const navigate = useNavigate();

    async function checkAuthState() {
        try {
            const current = await getCurrentUser();
            setIsVisible(false)
        } catch (err) {
            signInWithRedirect()
            // setTimeout(() => {
            //     navigate("/login");
            // }, 2750);
        }
    }

    useEffect(() => {
        checkAuthState()
    })

    return (
        <div>
            {isVisible && <LoadingPage />}
            {!isVisible && <Comp {...props} />}
        </div>
    )
}

export default protectedRoute