import React, { useEffect, useState } from 'react'
import './styles/CustomAlert.css'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function CustomAlert({ show, props, toggle }) {

    const [alertID, setalertID] = useState("") //animation purposes

    useEffect(() => {
        //start timer for close
        if (!show) return
        setalertID("alert-in")
        setTimeout(() => {
            setalertID("alert-out")
            toggle(false)
        }, 2000);
    }, [show])

    function getAlertType(field) {
        const alertColors = ['#f34133', '#75b8fe', '#46d2a3'] //[red,blue,green] dceefa
        const alertIcons = [<ReportProblemOutlinedIcon />, <InfoOutlinedIcon />, <CheckOutlinedIcon />]
        return (<div className='alert-modal-body' style={{
            // backgroundColor: alertColors[props.alertType] + '33',
            border: '0px solid ' + alertColors[props.alertType],
            color: '#ffffff'
        }}>

            <div className='linear-gradient-circle' id={'alert-' + props.alertType}>
                {alertIcons[props.alertType]}
            </div>
            <h5 style={{ color: alertColors[props.alertType],fontWeight: 600 }}>{props.message}</h5>
        </div>)
    }


    return (
        <div className='alert-modal-container' id={alertID} >
            {getAlertType()}
        </div>
    )
}

