import React from 'react'
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import TerminalIcon from '@mui/icons-material/Terminal';
import BarChartIcon from '@mui/icons-material/BarChart';
import SimCardIcon from '@mui/icons-material/SimCard';
import SettingsIcon from '@mui/icons-material/Settings';

export default function TabNavigator({ selectedDisplayTab, setselectedDisplayTab }) {
  return (
    <div className='display-tab-toggle-button-container'>
      <div className='display-tab-toggle-button' onClick={() => setselectedDisplayTab(0)} style={{
        borderBottom: selectedDisplayTab === 0 ? '3px solid #042855' : '3px solid transparent'
      }}>
        {/* Data */}
        <BarChartIcon sx={{ fontSize: '21px', color: selectedDisplayTab === 0 ? '#042855' : 'gray' }} />
        <h4 style={{ margin: '0px', marginLeft: '9px', color: selectedDisplayTab === 0 ? '#042855' : 'gray' }}>Live Data</h4>
      </div>
      <div className='display-tab-toggle-button' onClick={() => setselectedDisplayTab(1)} style={{
        borderBottom: selectedDisplayTab === 1 ? '3px solid #042855' : '3px solid transparent'
      }}>
        {/* Stats */}
        <BubbleChartIcon sx={{ fontSize: '21px', color: selectedDisplayTab === 1 ? '#042855' : 'gray' }} />
        <h4 style={{ margin: '0px', marginLeft: '9px', color: selectedDisplayTab === 1 ? '#042855' : 'gray' }}>Statistics</h4>
      </div>
      <div className='display-tab-toggle-button' onClick={() => setselectedDisplayTab(2)} style={{
        borderBottom: selectedDisplayTab === 2 ? '3px solid #042855' : '3px solid transparent'
      }}>
        {/* Terminal */}
        <TerminalIcon sx={{ fontSize: '21px', color: selectedDisplayTab === 2 ? '#042855' : 'gray' }} />
        <h4 style={{ margin: '0px', marginLeft: '9px', color: selectedDisplayTab === 2 ? '#042855' : 'gray' }}>Logs</h4>
      </div>

      <div className='display-tab-toggle-button' onClick={() => setselectedDisplayTab(4)} style={{
        borderBottom: selectedDisplayTab === 4 ? '3px solid #042855' : '3px solid transparent'
      }}>
        {/* Terminal */}
        <SettingsIcon sx={{ fontSize: '21px', color: selectedDisplayTab === 4 ? '#042855' : 'gray' }} />
        <h4 style={{ margin: '0px', marginLeft: '9px', color: selectedDisplayTab === 4 ? '#042855' : 'gray' }}>Settings</h4>
      </div>
      <div className='display-tab-toggle-button' onClick={() => setselectedDisplayTab(3)} style={{
        borderBottom: selectedDisplayTab === 3 ? '3px solid #042855' : '3px solid transparent'
      }}>
        {/* Terminal */}
        <SimCardIcon sx={{ fontSize: '21px', color: selectedDisplayTab === 3 ? '#042855' : 'gray' }} />
        <h4 style={{ margin: '0px', marginLeft: '9px', color: selectedDisplayTab === 3 ? '#042855' : 'gray' }}>Sim Card</h4>
      </div>
    </div>
  )
}
