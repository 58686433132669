import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import "./styles/ProfileDropdown.css";


const ProfileDropdown = ({ handleProfileDropDown, handleLogout }) => {
  return (
    <div className='dropdown-backdrop' onClick={handleProfileDropDown}>
      <div className='profile-dropdown'>
        {/* <div className='dropdown-item'>
                        <EditOutlinedIcon sx={{ maxWidth: '25px', color: '#636D73',margin: '12px' }} />
                        <p>Edit Profile</p>
                    </div> */}
        <div className='dropdown-item' onClick={handleLogout}>
          <LogoutIcon style={{ maxWidth: '25px', maxHeight: '20px', margin: '12px' }} />
          <p> Logout </p>
        </div>
      </div >
    </div >
  );
};

export default ProfileDropdown;

