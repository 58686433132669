import React, { useEffect } from 'react'
import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'
import { ReactComponent as FridgelinkLogo } from '../assets/FridgeLink_Final.svg'
import '../components/styles/LogoAnimation.css'
import './styles/LoadingPage.css'
import { useNavigate } from 'react-router-dom'

export default function Callback() {
    const navigate = useNavigate()

    useEffect(() => {
        handleSignIn()
    }, []);


    async function handleSignIn() {
        const credentials = await fetchAuthSession();
        attachPolicy(credentials)
        navigate('/')
    }

    function attachPolicy(credentials) {
        var Iot = new AWS.Iot({
            region: process.env.REACT_APP_REGION, apiVersion: process.env.REACT_APP_API_VERSION,
            credentials: credentials.credentials
        });
        var params = { policyName: process.env.REACT_APP_POLICY_NAME, target: credentials.identityId };
        console.log("Attach IoT Policy: " + process.env.REACT_APP_POLICY_NAME + " with cognito identity id: " + credentials.identityId);
        Iot.attachPolicy(params, function (err, data) {
            if (err) {
                if (err.code !== 'ResourceAlreadyExistsException') {
                    console.log(err);
                }
            }
            else {
                console.log("Successfully attached policy with the identity", data);
            }
        });
    }

    return (
        <div className='loading-container'>
            <div className='logo-container'>
                <FridgelinkLogo />
            </div>
        </div>
    )
}
