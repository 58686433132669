import React, { useState, useEffect, useRef } from 'react'
import { PuffLoader } from 'react-spinners';
import { dataColors } from '../../constants/colors';
import CCMGraph from './CCMGraph';
import HCCGraph from './HCCGraph';
import CASEMGraph from './CASEMGraph';
import './styles/CustomGraph.css'
import { getMidnightDate } from './Functions';

export default function GraphContainer({
  displayDataUpdated,
  setdisplayDataUpdated,
  deviceType,
  displayDate,
  tempTracersVisible,
  relayBarsVisible,
  targetOffsetsVisible,
  powerTracersVisible,
  toggleDataVisibility,
  tempDisplayData,
  relayDisplayData,
  powerDisplayData,
  powerFactorDisplayData,
  graphLoading,
  tempAxisConstants,
  powerAxisConstants,
  liveDeviceProps,
  deviceChannelDataLive,
  tempGraphComponentValues,
  settempGraphComponentValues,
  relayGraphComponentValues,
  setrelayGraphComponentValues,
  targetOffsetComponentValues,
  settargetOffsetComponentValues,
  powerGraphComponentValues,
  setpowerGraphComponentValues,
  powerFactorGraphComponentValues,
  setpowerFactorGraphComponentValues,
}) {


  const [scrollAmount, setscrollAmount] = useState(100); //Zoom func
  const [mouseIsDown, setmouseIsDown] = useState(false); //Panning
  const [mousePositionXY, setmousePositionXY] = useState({ x: 0, y: 0 }); //Zoom + Pan
  const [graphTranslateX, setgraphTranslateX] = useState(0); //Panning
  const [mainCanvasWidthOnMouseDown, setmainCanvasWidthOnMouseDown] = useState(1000); //Panning
  const [graphVerticalOverflows, setgraphVerticalOverflows] = useState({ top: 0, bottom: 0 }); //added space on top and bottom of graph
  const preciseTracerValues = useRef({ 'channel1': null });
  const [showHoverAxis, setshowHoverAxis] = useState(false);
  const [hoverAxisCoordinates, sethoverAxisCoordinates] = useState({ x: 0, y: 0, time: '00:00' });
  const powerFactorAxisConstants = { max: 100, min: 0 } //(1.0-0.0) multiplied by 100 
  const powerFactorOverflows = { top: 0, bottom: 0 } //(1.0-0.0) multiplied by 100 

  const numVerticalLines = 12
  const numTempHorizontalLines = deviceType === 1 ? 15 : 10
  const numPowerHorizontalLines = 10
  const graphViewBoxX = 1500
  const graphViewBoxY = deviceType === 1 ? 720 : 900
  const graphContainerWidth = graphViewBoxX//graphViewBoxX
  const tempContainerHeight = deviceType === 1 ? 600 : 420
  const relayContainerHeight = deviceType === 0 ? 30 : 150
  const yAxisValuesWidth = deviceType === 1 ? 60 : 120 //left
  const yAxisPowerFactorValuesWidth = 60//right
  const xAxisValuesHeight = 30//bottom
  const relayGraphWidth = graphViewBoxX//graphViewBoxX
  const relayGraphHeight = relayContainerHeight
  const tempGraphWidth = graphContainerWidth - yAxisValuesWidth//HCC
  const ccmGraphWidth = graphContainerWidth - yAxisValuesWidth - yAxisPowerFactorValuesWidth//CCM
  const tempGraphHeight = tempContainerHeight - xAxisValuesHeight

  useEffect(() => {// Init graph display
    if (displayDataUpdated) {
      const midnightStart = getMidnightDate(displayDate)
      const overflows = calcOverflows()
      if (deviceType === 0) { //CCM
        constructTempGraphs(tempDisplayData, midnightStart, overflows)
        constructRelayGraphs(relayDisplayData, midnightStart)
        constructPowerGraphs(powerDisplayData, midnightStart)
        constructPowerFactorGraphs(powerFactorDisplayData, midnightStart)
        if (tempDisplayData.length > 0) toggleDataVisibility(100, "temp", false)
        if (relayDisplayData.length > 0) toggleDataVisibility(100, "relay", false)
        toggleDataVisibility(100, "target", false)
      } else if (deviceType === 1) {//HCC
        constructTempGraphs(tempDisplayData, midnightStart, overflows)
        constructRelayGraphs(relayDisplayData, midnightStart)
        if (tempDisplayData.length > 0) toggleDataVisibility(100, "temp", false)
        // if (powerDisplayData.length > 0) toggleDataVisibility(100, "power", false)
      }
      setdisplayDataUpdated(false)
    }
  }, [displayDataUpdated]);

  function calcOverflows() {
    const tempVariance = tempAxisConstants.max - tempAxisConstants.min
    const mod = (tempVariance) % numTempHorizontalLines //15
    let bottomOverflow = 0
    let topOverflow = 0
    if (mod >= 1 && mod <= 7) {//.0
      const invMod = numTempHorizontalLines - mod
      bottomOverflow = Math.floor(invMod / 2)
      topOverflow = invMod - bottomOverflow
    } else {//.5
      const modValue = mod === 0 ? 15 : mod
      const invModCont = (numTempHorizontalLines + numTempHorizontalLines / 2) - modValue
      bottomOverflow = (invModCont + 0.5) / 2
      topOverflow = invModCont - bottomOverflow
    }
    setgraphVerticalOverflows({ top: topOverflow, bottom: bottomOverflow })
    return ({ top: topOverflow, bottom: bottomOverflow })
  }

  function constructTempGraphs(graphDisplayDataRecent, midnightStart, overflows) { //Get Line(temp) Graph Display components
    let allPathValuesObj = {} //for tracing {temp1: [{x: , y:}, {x: , y:}, ...]}
    const deviceGraphData = [...graphDisplayDataRecent]
    const tempVariance = tempAxisConstants.max - tempAxisConstants.min
    const tempVarWithOverflow = tempVariance + overflows.top + overflows.bottom
    for (let index = 0; index < deviceGraphData.length; index++) {
      const pointData = deviceGraphData[index];
      const dateTimeOnPoint = new Date(pointData.timestamp)
      const timeElapsedFromStart = (dateTimeOnPoint - midnightStart) / (1000 * 60)//inMinutes
      let xVal = timeElapsedFromStart
      const pointTempValue = deviceType === 0 ? pointData.ctlTmp : pointData.temp
      let yVal = tempVarWithOverflow - (pointTempValue) + (tempAxisConstants.min - overflows.bottom)
      const minutesInFullDay = 60 * 24
      const currentGraphWidth = deviceType === 0 ? (tempGraphWidth - yAxisPowerFactorValuesWidth) : tempGraphWidth
      let xCoordinate = (currentGraphWidth) / minutesInFullDay * xVal
      let yCoordinate = tempGraphHeight / tempVarWithOverflow * yVal
      let lineKey = null
      if (pointData?.metadata?.channel) {
        lineKey = 'channel' + pointData.metadata.channel
      } else {
        lineKey = 'channel' + 1
      }
      if (lineKey in allPathValuesObj) {
        allPathValuesObj[lineKey].push({ x: xCoordinate, y: yCoordinate })
      } else {
        allPathValuesObj[lineKey] = [{ x: xCoordinate, y: yCoordinate }]
      }
    }
    let compiledCoordinateObj = getFlippedArraysIfWrongWay(allPathValuesObj)
    // constructTargetOffsetLines(tempVarWithOverflow, overflows)//Construct UI component data for target offset lines
    settempGraphComponentValues(compiledCoordinateObj)
  }

  function constructRelayGraphs(relayDisplayDataRecent, midnightStart) {//Get Bar(relayState) Graph Display components
    let allPathValuesObj = {} //for tracing {rel: [{x: , y: , state:}, {x: , y: , state:}, ...]}
    const deviceRelayData = [...relayDisplayDataRecent]
    for (let index = 0; index < deviceRelayData.length; index++) {
      const pointData = deviceRelayData[index];
      const dateTimeOnPoint = new Date(pointData.timestamp)
      const timeElapsedFromStart = (dateTimeOnPoint - midnightStart) / (1000 * 60)//inMinutes
      let xVal = timeElapsedFromStart
      let yVal = 1
      const minutesInFullDay = 60 * 24
      const currentGraphWidth = deviceType === 0 ? (tempGraphWidth - yAxisPowerFactorValuesWidth) : tempGraphWidth
      let xCoordinate = (currentGraphWidth) / minutesInFullDay * xVal
      let yCoordinate = relayGraphHeight / 8 * yVal
      let lineKey = null
      if (pointData?.metadata?.channel) {
        lineKey = 'channel' + pointData.metadata.channel
      } else {
        lineKey = 'channel' + 1
      }
      let state = null
      if (pointData?.type?.includes("ccm")) {
        state = pointData.ctlRel
      } else {
        state = pointData.relStat
      }
      if (lineKey in allPathValuesObj) {
        allPathValuesObj[lineKey].push({ x: xCoordinate, y: yCoordinate, state })
      } else {
        allPathValuesObj[lineKey] = [{ x: xCoordinate, y: yCoordinate, state }]
      }
    }
    setrelayGraphComponentValues(allPathValuesObj)
  }
  function constructTargetOffsetLines(tempVarWithOverflow, overflows) {
    let offsetLineDataObj = {}
    Object.keys(deviceChannelDataLive).forEach(shelfIndexKey => {
      const setpointValue = liveDeviceProps.conType === 1 ? liveDeviceProps.gbsp : deviceChannelDataLive[shelfIndexKey].setpoint //from global/local
      const offsetTargetValue = ((setpointValue * 5) + 50) + deviceChannelDataLive[shelfIndexKey].offset
      let yVal = tempVarWithOverflow - (offsetTargetValue) + (tempAxisConstants.min - overflows.bottom)
      let yCoordinate = tempGraphHeight / tempVarWithOverflow * yVal
      offsetLineDataObj[shelfIndexKey] = { y: yCoordinate }
    });
    settargetOffsetComponentValues(offsetLineDataObj)
  }

  function constructPowerGraphs(graphDisplayDataRecent, midnightStart) { //Get Line(temp) Graph Display components
    let allPathValuesObj = {} //for tracing {temp1: [{x: , y:}, {x: , y:}, ...]}
    const deviceGraphData = []
    const overflows = { top: 100, bottom: powerAxisConstants.min }
    deviceGraphData.push(graphDisplayDataRecent)
    const powerVariance = powerAxisConstants.max - powerAxisConstants.min
    const powerVarWithOverflow = powerVariance + overflows.top + overflows.bottom
    deviceGraphData.forEach((dataPoint, index) => {
      const pointIndex = 'power2'
      const pathValues = []
      for (let index = 0; index < dataPoint.length; index++) {
        const pointData = dataPoint[index];
        const dateTimeOnPoint = new Date(pointData.timestamp)
        const timeElapsedFromStart = (dateTimeOnPoint - midnightStart) / (1000 * 60)//inMinutes
        let xVal = timeElapsedFromStart
        let yVal = powerVarWithOverflow - (pointData.instPwr) + (powerAxisConstants.min - overflows.bottom)
        const minutesInFullDay = 60 * 24
        const currentGraphWidth = deviceType === 0 ? (tempGraphWidth - yAxisPowerFactorValuesWidth) : tempGraphWidth
        let xCoordinate = (currentGraphWidth) / minutesInFullDay * xVal
        let yCoordinate = tempGraphHeight / powerVarWithOverflow * yVal
        pathValues.push({ x: xCoordinate, y: yCoordinate })
      }
      allPathValuesObj[pointIndex] = pathValues
    })
    let compiledCoordinateObj = getFlippedArraysIfWrongWay(allPathValuesObj) //ensure data in correct order
    setpowerGraphComponentValues(compiledCoordinateObj)
  }

  function constructPowerFactorGraphs(graphDisplayDataRecent, midnightStart) { //Get Line(temp) Graph Display components
    let allPathValuesObj = {} //for tracing {temp1: [{x: , y:}, {x: , y:}, ...]}
    const deviceGraphData = []
    deviceGraphData.push(graphDisplayDataRecent)
    const powerFactorVariance = powerFactorAxisConstants.max - powerFactorAxisConstants.min
    const powerVarWithOverflow = powerFactorVariance + powerFactorOverflows.top + powerFactorOverflows.bottom
    deviceGraphData.forEach((dataPoint, index) => {
      const pointIndex = 'pwrFact3'
      const pathValues = []
      for (let index = 0; index < dataPoint.length; index++) {
        const pointData = dataPoint[index];
        const dateTimeOnPoint = new Date(pointData.timestamp)
        const timeElapsedFromStart = (dateTimeOnPoint - midnightStart) / (1000 * 60)//inMinutes
        let xVal = timeElapsedFromStart
        let yVal = powerVarWithOverflow - (pointData.pwrFact * 100) + (powerFactorAxisConstants.min - powerFactorOverflows.bottom)
        const minutesInFullDay = 60 * 24
        const currentGraphWidth = tempGraphWidth - yAxisPowerFactorValuesWidth
        let xCoordinate = (currentGraphWidth) / minutesInFullDay * xVal
        let yCoordinate = tempGraphHeight / powerVarWithOverflow * yVal
        pathValues.push({ x: xCoordinate, y: yCoordinate })
      }
      allPathValuesObj[pointIndex] = pathValues
    })
    let compiledCoordinateObj = getFlippedArraysIfWrongWay(allPathValuesObj) //ensure data in correct order
    setpowerFactorGraphComponentValues(compiledCoordinateObj)
  }

  function getFlippedArraysIfWrongWay(allPathValuesObj) {
    let compiledCoordinateObj = {}
    Object.keys(allPathValuesObj).forEach(lKey => {
      const graphCoordinateArr = allPathValuesObj[lKey]
      //flip array if wrong order (compare first and last item in array to determine)
      if (graphCoordinateArr[0]?.x > graphCoordinateArr[graphCoordinateArr.length - 1]?.x) {
        const rev = graphCoordinateArr.reverse()
        compiledCoordinateObj[lKey] = rev
      } else {
        compiledCoordinateObj[lKey] = graphCoordinateArr
      }
    });
    return compiledCoordinateObj
  }

  //Panning + Zooming
  function zoomGraph(e) {
    const mouseXPosition = e.clientX
    let containerSVGElement = document.getElementById("svg-axis-container-group")
    const canvasLeft = containerSVGElement.getBoundingClientRect().left
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    const canvasToMousePositionRatio = ((mouseXPosition - canvasLeft) / canvasWidth)//0 -> 1 (FINAL)--This the onnnneeee
    const graphWidth = deviceType === 0 ? ccmGraphWidth : tempGraphWidth
    const transitionVal = (canvasToMousePositionRatio * (graphWidth) * e.deltaY) / 100
    setscrollAmount(prev => {
      if ((prev + e.deltaY <= 100)) {
        return 100
      } else if ((prev + e.deltaY >= 2400)) {
        return 2400
      } else {
        return prev + e.deltaY
      }
    })
    if (scrollAmount < 2400) {//prevent translation on max zoomedIn
      setgraphTranslateX(prev => {
        if ((prev + transitionVal <= 0)) {
          return 0
        } else {
          return prev + transitionVal
        }
      })
    }
    return false
  }

  function handleMouseDown(e) {//Start panning
    let containerSVGElement = document.getElementById("svg-container-canvas")
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    setmainCanvasWidthOnMouseDown(canvasWidth)
    const mouseXPosition = e.clientX
    const mouseYPosition = e.clientY
    setmouseIsDown(true)
    setmousePositionXY({ x: mouseXPosition, y: mouseYPosition })
  }

  function handleMouseMove(event) {
    if (!mouseIsDown) {
      onHoverShowAxis(event)
    } else {
      const panMultiplier = graphContainerWidth / mainCanvasWidthOnMouseDown //ensures mousePosiion correlates with translation
      const deltaX = (event.clientX - mousePositionXY.x) * panMultiplier;
      let canvasSVGElement = document.getElementById("svg-container-canvas") //container of displayed data (WINDOW)
      const canvasLeft = canvasSVGElement.getBoundingClientRect().left
      const canvasWidth = canvasSVGElement.getBoundingClientRect().width
      let containerSVGElement = document.getElementById("svg-axis-container-group") //displayed data
      const containerLeft = containerSVGElement.getBoundingClientRect().left
      const containerWidth = containerSVGElement.getBoundingClientRect().width
      const canvasWidthMinusAxisTextWidth = canvasWidth * ((graphViewBoxX - yAxisValuesWidth) / graphViewBoxX)
      const axisContainerBaseLeft = canvasLeft + (canvasWidth * (yAxisValuesWidth / graphViewBoxX))
      setgraphTranslateX(prev => {
        if ((prev - deltaX <= 0)) {
          return 0
        } else if (((containerWidth - (axisContainerBaseLeft - containerLeft)) <= (canvasWidthMinusAxisTextWidth)) && deltaX < 0) {
          return prev
        } else {
          return prev - deltaX
        }
      })
      setmousePositionXY({ x: event.clientX, y: event.clientY });
    }
  }

  function handleMouseUp() { //Stop panning 
    if (!mouseIsDown) return
    setmouseIsDown(false)
    setmousePositionXY({ x: 0, y: 0 })
  }

  function onHoverShowAxis(event) {
    const mouseXPosition = event.clientX
    let containerSVGElement = document.getElementById("svg-axis-container-group")
    const canvasLeft = containerSVGElement.getBoundingClientRect().left
    const canvasWidth = containerSVGElement.getBoundingClientRect().width
    const canvasToMousePositionRatio = ((mouseXPosition - canvasLeft) / (canvasWidth))//0 -> 1 (FINAL)--This the onnnneeee
    if (canvasToMousePositionRatio < 0 || (canvasToMousePositionRatio > 1)) {
      if (showHoverAxis) setshowHoverAxis(false)
      return
    } else {
      if (!showHoverAxis) setshowHoverAxis(true)
    }
    const graphWidth = deviceType === 0 ? ccmGraphWidth : tempGraphWidth
    const xCoordinateValue = canvasToMousePositionRatio * graphWidth
    let preciseTracerValObj = {}
    let finalXvalueForAll = 0
    const compiledGraphComponentValues = deviceType === 0 ?
      { ...tempGraphComponentValues, ...powerGraphComponentValues, ...powerFactorGraphComponentValues }
      : tempGraphComponentValues
    Object.keys(compiledGraphComponentValues).forEach(key => {
      let currentAxisConstants = null
      let currentOverflows = null
      if (key.includes("power")) {
        currentAxisConstants = powerAxisConstants
        currentOverflows = { top: 100, bottom: powerAxisConstants.min }
      } else if (key.includes("pwrFact")) {
        currentAxisConstants = powerFactorAxisConstants
        currentOverflows = powerFactorOverflows
      } else {
        currentAxisConstants = tempAxisConstants
        currentOverflows = graphVerticalOverflows
      }
      const variance = currentAxisConstants.max - currentAxisConstants.min
      const varianceWithOverflow = variance + currentOverflows.top + currentOverflows.bottom
      if (compiledGraphComponentValues[key].length > 0) {
        let finalDataVal = null
        let finalXval = null
        let finalYval = null
        for (let index = 0; index < compiledGraphComponentValues[key].length; index++) {
          const value = compiledGraphComponentValues[key][index];
          if (xCoordinateValue > value.x) {
            const yCoordinateValue = value.y
            const yValOnCanvas = yCoordinateValue * varianceWithOverflow / (tempGraphHeight)
            finalDataVal = varianceWithOverflow + (currentAxisConstants.min - currentOverflows.bottom) - (yValOnCanvas)
            if (key.includes("pwrFact")) finalDataVal = finalDataVal / 100
            finalXval = value.x
            finalYval = value.y
          }
        }
        const temp = finalDataVal === null ? null : finalDataVal.toFixed(2)
        const preciseDataObj = { temp, x: finalXval, y: finalYval }
        preciseTracerValObj[key] = preciseDataObj
        if (finalXval > finalXvalueForAll) {
          finalXvalueForAll = finalXval
        }
      }
    });
    Object.keys(preciseTracerValObj).forEach(valueKey => {
      if (xCoordinateValue - 30 > finalXvalueForAll || preciseTracerValObj[valueKey].x < finalXvalueForAll) {
        preciseTracerValObj[valueKey].x = null
        preciseTracerValObj[valueKey].y = null
        preciseTracerValObj[valueKey].temp = null
      }
    });
    // console.log(JSON.stringify(preciseTracerValObj))
    preciseTracerValues.current = preciseTracerValObj
    const finalXvalue = (canvasToMousePositionRatio) * 24 * 60//minutes
    const hours = parseInt(finalXvalue / 60).toString().padStart(2, '0')
    const min = parseInt(finalXvalue % 60).toString().padStart(2, '0')
    const timeValue = hours + ":" + min
    sethoverAxisCoordinates({ x: (canvasToMousePositionRatio * (graphWidth)), y: 0, time: timeValue })
  }

  const getGraphTransformation = () => { //Graph transformation based on zoom and pan amounts
    let xScale = scrollAmount / 100
    // if (xScale < 1) xScale = 1
    let xTranslate = -graphTranslateX
    // if (xTranslate > 0) xTranslate = 0
    const yScale = 1
    return 'translate(' + (xTranslate) + ', ' + 0 + ') scale(' + xScale + ', ' + yScale + ')'
  }

  function renderVerticalLines() { //For x-Axis values( date time ) {parallel to y-Axis}
    const lineDataArr = []
    const zoomScale = parseInt(scrollAmount / 200)
    let renderMultiplier = 1 + zoomScale
    let numLinesToRender = renderMultiplier * numVerticalLines
    const currentGraphWidth = deviceType === 0 ? (tempGraphWidth - yAxisPowerFactorValuesWidth) : tempGraphWidth
    for (let index = 0; index < numLinesToRender; index++) {
      const xValue = (currentGraphWidth) / numLinesToRender * (index)
      const panningAdded = xValue
      const lineX = panningAdded
      lineDataArr.push({ x: lineX })
    }
    return lineDataArr
  }

  function renderHorizontalLines(graphType) {//For y-Axis values( temperature ) {parallel to x-Axis}
    const lineDataArr = []
    const numHorizontalLines = graphType === "temp" ? numTempHorizontalLines : numPowerHorizontalLines
    for (let index = 0; index < numHorizontalLines; index++) {
      const lineY = (tempGraphHeight) / numHorizontalLines * (numHorizontalLines - index)
      lineDataArr.push({ y: lineY })
    }
    return lineDataArr
  }

  function renderVerticalText() { //Labels for x-Axis values (date time)
    const lineDataArr = []
    let xScale = scrollAmount / 100
    const zoomScale = parseInt(scrollAmount / 200)
    let renderMultiplier = 1 + zoomScale
    let numLinesToRender = renderMultiplier * numVerticalLines
    const currentGraphWidth = deviceType === 0 ? (tempGraphWidth - yAxisPowerFactorValuesWidth) : tempGraphWidth
    for (let index = 0; index < numLinesToRender; index++) {
      const xValue = (xScale * (currentGraphWidth) / numLinesToRender) * (index)
      let xTranslate = -graphTranslateX
      // if (xTranslate > 0) xTranslate = 0
      const xValMinusLeft = xValue + xTranslate
      const totalMinutes = 1440 / numLinesToRender * index
      let hour = parseInt(totalMinutes / 60)
      let min = parseInt(totalMinutes % 60)
      let hourValue = '0' + hour
      let minValue = '0' + min
      const time = hourValue.slice(-2) + ":" + minValue.slice(-2)
      lineDataArr.push({ x: xValMinusLeft, time })
    }
    return lineDataArr
  }

  function renderHorizontalText(graphType) {//For y-Axis values( temperature ) {parallel to x-Axis}
    const lineDataArr = []
    const numHorizontalLines = graphType === "temp" ? numTempHorizontalLines : numPowerHorizontalLines
    const axisConstants = graphType === "temp" ? tempAxisConstants : powerAxisConstants
    const verticalOverflows = graphType === "temp" ? graphVerticalOverflows : { top: 100, bottom: axisConstants.min }
    for (let index = 0; index < numHorizontalLines; index++) {
      const lineY = (tempGraphHeight) / numHorizontalLines * (numHorizontalLines - index)
      const fullVarianceWithOverflows = (axisConstants.max + verticalOverflows.top) - (axisConstants.min - verticalOverflows.bottom)
      const pwrFactValue = index / numHorizontalLines
      const calculatedValue = (index * (fullVarianceWithOverflows / numHorizontalLines)) + (axisConstants.min - verticalOverflows.bottom)
      const value = graphType === "powerFactor" ? pwrFactValue : calculatedValue
      lineDataArr.push({ y: lineY, value: value.toFixed(1) })
    }
    return lineDataArr
  }

  function renderTempGraph() {
    const componentsToRender = []
    Object.keys(tempGraphComponentValues).forEach((key, index) => {
      const channelNum = key.slice(-1)
      if (tempTracersVisible[channelNum]) {
        let pathString = ""
        const graphColor = dataColors[channelNum]
        const graphValues = tempGraphComponentValues[key]
        for (let index = 0; index < graphValues.length; index++) {
          if (index === 0) {
            pathString = "M"
          } else {
            let prevPoint = null;
            let pointTimeDifference = 0
            prevPoint = graphValues[index - 1];
            pointTimeDifference = graphValues[index].x - prevPoint.x
            // if (pointTimeDifference > 10) {
            //   pathString = pathString + " M"
            // } else {
            pathString = pathString + " L"
            // }
          }
          pathString = pathString + graphValues[index].x + " " + graphValues[index].y
        }
        componentsToRender.push(<path key={key} id='key' d={pathString} stroke={graphColor} strokeWidth={3} fill='none' vectorEffect={'non-scaling-stroke'} />)
      }
    });
    return (<>
      {componentsToRender}
    </>)
  }

  function renderRelayGraph() {
    const componentsToRender = []
    let numBarsRendered = 1
    Object.keys(relayGraphComponentValues).forEach(key => {
      const channelNum = key.slice(-1)
      if (relayBarsVisible[channelNum]) {
        let pathString = ""
        const barColor = dataColors[channelNum]
        const graphValues = relayGraphComponentValues[key]
        for (let index = 0; index < graphValues.length; index++) {
          let prevPoint = { state: -1 };
          if (index !== 0) {
            prevPoint = graphValues[index - 1];
          }
          if (prevPoint.state === 1) {
            pathString = pathString + " L"
          } else {
            pathString = pathString + " M"
          }
          pathString = pathString + graphValues[index].x + " " + (graphValues[index].y + (numBarsRendered * 12))
        }
        componentsToRender.push(<path key={key} d={pathString} stroke={barColor + "93"} strokeWidth={12} fill='none' />)
        numBarsRendered += 1
      }
    });

    return (<>
      {componentsToRender}
    </>)
  }

  function renderTargetOffsetLines() {//For y-Axis values( temperature ) {parallel to x-Axis}
    const lineDataArr = []
    Object.keys(targetOffsetComponentValues).forEach(shelfIndexKey => {
      if (targetOffsetsVisible[shelfIndexKey]) {
        let lineY = targetOffsetComponentValues[shelfIndexKey].y
        if (lineY < 0) { //if outside of graph in/max
          lineY = 0
        }
        const lineColor = dataColors[shelfIndexKey]
        lineDataArr.push(<line key={shelfIndexKey} y1={lineY} x1="0" y2={lineY} x2={tempGraphWidth} stroke={lineColor} strokeWidth={2} strokeDasharray={"9 3"} />)
      }
    });
    return (<>
      {lineDataArr}
    </>
    )
  }

  function renderPowerGraph() {
    const componentsToRender = []
    Object.keys(powerGraphComponentValues).forEach(key => {
      // if (powerTracersVisible[key]) {
      let pathString = ""
      const channelNum = key.slice(-1)
      const graphColor = dataColors[channelNum]
      const graphValues = powerGraphComponentValues[key]
      for (let index = 0; index < graphValues.length; index++) {
        if (index === 0) {
          pathString = "M"
        } else {
          let prevPoint = null;
          let pointTimeDifference = 0
          prevPoint = graphValues[index - 1];
          pointTimeDifference = graphValues[index].x - prevPoint.x
          // if (pointTimeDifference > 3) {
          //   pathString = pathString + " M"
          // } else {
          pathString = pathString + " L"
          // }
        }
        pathString = pathString + graphValues[index].x + " " + graphValues[index].y
      }
      componentsToRender.push(<path key={key} d={pathString} stroke={graphColor} strokeWidth={3} fill='none' vectorEffect={'non-scaling-stroke'} />)
      // }
    });
    return (<>
      {componentsToRender}
    </>)
  }

  function renderPowerFactorGraph() {
    const componentsToRender = []
    Object.keys(powerFactorGraphComponentValues).forEach(key => {
      // if (powerTracersVisible[key]) {
      let pathString = ""
      const channelNum = key.slice(-1)
      const graphColor = dataColors[channelNum]
      const graphValues = powerFactorGraphComponentValues[key]
      for (let index = 0; index < graphValues.length; index++) {
        if (index === 0) {
          pathString = "M"
        } else {
          let prevPoint = null;
          let pointTimeDifference = 0
          prevPoint = graphValues[index - 1];
          pointTimeDifference = graphValues[index].x - prevPoint.x
          // if (pointTimeDifference > 3) {
          //   pathString = pathString + " M"
          // } else {
          pathString = pathString + " L"
          // }
        }
        pathString = pathString + graphValues[index].x + " " + graphValues[index].y
      }
      componentsToRender.push(<path key={key} d={pathString} stroke={graphColor} strokeWidth={3} fill='none' vectorEffect={'non-scaling-stroke'} />)
      // }
    });
    return (<>
      {componentsToRender}
    </>)
  }

  const renderPreciseValueChip = (valueObj, key) => {
    let measurement = ''
    if (key.includes('power')) {
      measurement = 'W'
    } else if (key.includes('channel')) {
      measurement = '\u00b0C'
    }
    return (
      <p>{(valueObj?.temp === null ? "N/A" : valueObj?.temp + measurement)}</p>
    )
  }

  const renderDeviceApplicableGraph = () => {
    switch (deviceType) {
      case 0://CCM
        return (<CCMGraph
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          handleMouseMove={handleMouseMove}
          zoomGraph={zoomGraph}
          graphViewBoxX={graphViewBoxX}
          graphViewBoxY={graphViewBoxY}
          yAxisValuesWidth={yAxisValuesWidth}
          xAxisValuesHeight={xAxisValuesHeight}
          renderHorizontalText={renderHorizontalText}
          renderHorizontalLines={renderHorizontalLines}
          renderVerticalText={renderVerticalText}
          renderVerticalLines={renderVerticalLines}
          graphContainerWidth={graphContainerWidth}
          ccmGraphWidth={ccmGraphWidth}
          tempGraphHeight={tempGraphHeight}
          renderTempGraph={renderTempGraph}
          preciseTracerValues={preciseTracerValues}
          graphTranslateX={graphTranslateX}
          getGraphTransformation={getGraphTransformation}
          showHoverAxis={showHoverAxis}
          hoverAxisCoordinates={hoverAxisCoordinates}
          scrollAmount={scrollAmount}
          tempContainerHeight={tempContainerHeight}
          renderPowerGraph={renderPowerGraph}
          renderPowerFactorGraph={renderPowerFactorGraph}
          renderRelayGraph={renderRelayGraph}
          relayGraphHeight={relayGraphHeight}
          relayContainerHeight={relayContainerHeight}
          yAxisPowerFactorValuesWidth={yAxisPowerFactorValuesWidth}
        />)
        break;
      case 1://HCC
        return (<HCCGraph
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          handleMouseMove={handleMouseMove}
          zoomGraph={zoomGraph}
          graphViewBoxX={graphViewBoxX}
          graphViewBoxY={graphViewBoxY}
          yAxisValuesWidth={yAxisValuesWidth}
          xAxisValuesHeight={xAxisValuesHeight}
          renderHorizontalText={renderHorizontalText}
          renderHorizontalLines={renderHorizontalLines}
          renderVerticalText={renderVerticalText}
          renderVerticalLines={renderVerticalLines}
          graphContainerWidth={graphContainerWidth}
          tempContainerHeight={tempContainerHeight}
          tempGraphWidth={tempGraphWidth}
          tempGraphHeight={tempGraphHeight}
          renderTargetOffsetLines={renderTargetOffsetLines}
          renderTempGraph={renderTempGraph}
          preciseTracerValues={preciseTracerValues}
          tempTracersVisible={tempTracersVisible}
          graphTranslateX={graphTranslateX}
          relayContainerHeight={relayContainerHeight}
          getGraphTransformation={getGraphTransformation}
          renderRelayGraph={renderRelayGraph}
          showHoverAxis={showHoverAxis}
          hoverAxisCoordinates={hoverAxisCoordinates}
          relayGraphHeight={relayGraphHeight}
          scrollAmount={scrollAmount}
        />)
        break;
      case 2://CASEM
        return (<CASEMGraph
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          handleMouseMove={handleMouseMove}
          zoomGraph={zoomGraph}
          graphViewBoxX={graphViewBoxX}
          graphViewBoxY={graphViewBoxY}
          yAxisValuesWidth={yAxisValuesWidth}
          xAxisValuesHeight={xAxisValuesHeight}
          renderHorizontalText={renderHorizontalText}
          renderHorizontalLines={renderHorizontalLines}
          renderVerticalText={renderVerticalText}
          renderVerticalLines={renderVerticalLines}
          graphContainerWidth={graphContainerWidth}
          tempGraphWidth={tempGraphWidth}
          tempGraphHeight={tempGraphHeight}
          renderTempGraph={renderTempGraph}
          preciseTracerValues={preciseTracerValues}
          graphTranslateX={graphTranslateX}
          getGraphTransformation={getGraphTransformation}
          showHoverAxis={showHoverAxis}
          hoverAxisCoordinates={hoverAxisCoordinates}
          scrollAmount={scrollAmount}
          tempContainerHeight={tempContainerHeight}
          renderPowerGraph={renderPowerGraph}
          powerTracersVisible={powerTracersVisible}
        />)
        break;
      default:
        break;
    }
  }

  return (
    <div className='graph-display-container'>
      <div className='legend-key-display-container'>
        {(showHoverAxis && !graphLoading) &&
          (Object.keys(preciseTracerValues.current).map((key) => {
            const channelIndex = key.slice(-1)
            if (tempTracersVisible[channelIndex]) {
              return (
                <div key={key} className='precise-value-container'>
                  <div style={{ marginRight: '3px', width: '12px', height: '12px', borderRadius: '30px', backgroundColor: dataColors[channelIndex] }}>
                  </div>
                  {renderPreciseValueChip(preciseTracerValues.current[key], key)}
                </div>)
            }
          }))}
      </div>
      {graphLoading ?
        <PuffLoader
          size={60}
          color="#042855" />
        :
        (renderDeviceApplicableGraph())
      }
    </div>
  )
}
