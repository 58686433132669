import { urlConfig } from "./ApiConfig"
import { get, post } from "aws-amplify/api"

export async function getAllCustomers() {
    try {
        const restOperation = get({
            apiName: 'fl2-rest-api-gateway',
            path: '/customers',
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.parse(error.response.body))
    }
}

export async function getSingleCustomer() {

}

export async function addCustomer(props) {
    try {
        const restOperation = post({
            apiName: 'fl2-rest-api-gateway',
            path: '/customers',
            options: {
                body: {
                    ...props
                }
            }
        });
        const { body } = await restOperation.response
        const json = await body.json()
        // console.log("SUCCESS: ", JSON.stringify(json))
        return json
    } catch (error) {
        // console.log("FAIL: ", JSON.parse(error.response.body))
    }
}

export async function editCustomer(customerId, props) {

}

export async function removeCustomer(customerId) {

}

export async function verifyDeviceCode(customerId, deviceCode) {

}

