import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './styles/Modals.css'
import { SyncLoader } from 'react-spinners';
import { addCustomer, editCustomer } from '../api/CustomerAPI';

export default function AddEditCustomer({ show, toggleModal, submit, modalInEditMode, selectedRecord, deleteClicked, toggleAlert, getDataFromDatabase }) {

  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (show && modalInEditMode) {
      populateFields()
    } else {
      clearFields()
    }
  }, [show]);

  function populateFields() {
    setname(selectedRecord.name)
    setaddress(selectedRecord.address)
    setemail(selectedRecord.email)
  }
  function clearFields() {
    setname("")
    setaddress("")
    setemail("")
  }

  function onChange(event) {
    switch (event.target.name) {
      case "name":
        setname(event.target.value)
        break;
      case "address":
        setaddress(event.target.value)
        break;
      case "email":
        setemail(event.target.value)
        break;
      default:
        break;
    }
  }

  async function onSubmit() {
    //validation
    setisLoading(true)
    const props = { name, address, email }
    let res = {}
    let message = "An error occured"
    if (modalInEditMode) {
      const customerId = selectedRecord.id
      res = await editCustomer(customerId, props)
      // console.log("Resp1: " + JSON.stringify(res))
    } else {
      if (name === "") {
        message = "Name is required"
      } else {
        res = await addCustomer(props)
        message = "An error occured"
      }
      // console.log("Resp2: " + JSON.stringify(res))
    }
    if (res?._id) {
      message = modalInEditMode ? "Customer updated" : "New Customer created"
      toggleAlert(true, 2, message)
      handleClose()
      getDataFromDatabase(4, true)
    } else {
      // message = res?.errors?.[0]?.message || message
      toggleAlert(true, 0, message)
    }
    setisLoading(false)
  }

  function handleClose() {
    //close method
    toggleModal(false, 4)
  }

  return (
    <>
      {show ? (
        <div className='backdrop' onClick={handleClose}>
          <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
            <div className="modal-header" >
              <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Customer"}</h3>
              <CloseIcon onClick={() => { handleClose() }} sx={{ cursor: 'pointer' }} />
            </div>
            {/* body container */}
            <form className='input-form'  >
              <div className="form-group-vertical">
                <label htmlFor="name" className="col-form-label">Name</label>
                <input value={name} type="text" autoComplete='off' id='name' placeholder='' className="modal-input" name="name" onChange={onChange} ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="address" className="col-form-label">Address</label>
                <input value={address} type="text" autoComplete='off' id='address' placeholder='' className="modal-input" name="address" onChange={onChange} ></input>
              </div>
              <div className="form-group-vertical">
                <label htmlFor="email" className="col-form-label">Email</label>
                <input value={email} type="text" autoComplete='off' id='email' placeholder='' className="modal-input" name="email" onChange={onChange} ></input>
              </div>
            </form>
            <div className='submit-container'>
              <button className='submit-btn remove-btn' disabled={!modalInEditMode}
                onClick={() => deleteClicked()}
                style={{
                  cursor: modalInEditMode ? "pointer" : 'default',
                  opacity: modalInEditMode ? 1 : 0
                }}>{"Delete"}</button>
              <button className='modal-submit-btn' onClick={() => { onSubmit() }} >
                {isLoading ?
                  <SyncLoader
                    size={6}
                    color="#ffffff"
                  /> : "Submit"
                }
              </button>
            </div>
          </div>
        </div >)
        : <></>}
    </>)
}
