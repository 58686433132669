import { getSingletonPubSub } from "./MyPubSub";

function compileTopics(topicTypes, data) {
  const topicsOut = []
  //topictypes: {0:dashboard, 1:pullup overview, 2:full overview}
  switch (topicTypes) {
    case 0: //dashboard topics
      data.forEach(dataObj => {
        topicsOut.push("$aws/events/presence/+/" + dataObj.thingName)
        topicsOut.push("device/" + dataObj.type + "/" + dataObj.thingName + "/telemetry/pwrStat")
      })
      break;
    case 1: //pullup overview topics (single device)
      switch (data.type) {
        case "ccm":
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlRel")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/conStat")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/instPwr")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/pwrFact")
          // topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/MONTHTODATE")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
          break;
        case "hcc":
          for (let index = 0; index < 8; index++) { //check channels on device data and refine loop// TODO
            const channelNum = index + 1
            topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/temp" + channelNum)
            topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/relStat" + channelNum)
          }
          break;
        case "casem":
          //TODO
          break;
        default:
          break;
      }
      break;
    case 2: //full overview topics
      switch (data.type) {
        case "ccm":
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlRel")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/conStat")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlSp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlDiff")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTime")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defInt")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/instPwr")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/pwrFact")
          // topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/MONTHTODATE")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
          break;
        case "hcc":
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/scrnStat")
          break;
        case "casem":
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
          topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }
  return topicsOut
}

//MQTT subscribe
export async function subscribeDashboardTopics(mqttSubscriptionRef, topicTypes, data, handleMqttMessage) { //power status + lwat... at the moment
  // unsubscribeFrom('dashboard')
  const topicsIn = compileTopics(topicTypes, data)
  // console.log("TOPICS: " + JSON.stringify(topicsIn))
  // console.log("SUBSCRIBE")
  const singletonRep = await getSingletonPubSub()
  mqttSubscriptionRef.current = singletonRep.subscribe({
    topics: [...topicsIn]
  }).subscribe({
    next: (data) => {
      let messageData = data
      const topic = data[Object.getOwnPropertySymbols(data)[0]]
      console.log('Message received', JSON.stringify({ topic, ...messageData }))
      handleMqttMessage({ ...messageData, topic })
    },
    error: (error) => console.error(error),
    complete: () => console.log('Done')
  });
}

// Publish New Control Settings
export async function pubsubPublishSettings(type, id, payload) { //Publish settings
  try {
    let singletonPubSub = await getSingletonPubSub()
    await singletonPubSub.publish({ topics: `device/${type}/${id}/cmd`, message: payload });
    const message = 'Settings updated successfully'
    console.log('Message published successfully');
  } catch (error) {
    const message = 'Error updating settings'
    console.error('Error publishing message:', error);
  }
}

// Control Settings Logs Subscription
// export async function subscribeSettingsLogs(type, id) {
//   const singletonRep = await getSingletonPubSub()
//   mqttSubscriptionRef.current = singletonRep.subscribe({
//     topics: `device/${type}/${id}/log`
//   }).subscribe({
//     next: (data) => {
//       console.log(data)
//     },
//     error: (error) => console.error(error),
//     complete: () => console.log('Done')
//   });
// }

// function subscribeDeviceOverviewTopics(deviceId, isExpanded) { //Single device Overview data
//     unsubscribeFrom("overview")
//     const topicsArray = getTopicArrayFor(deviceId, isExpanded)
//     deviceOverviewSub.current = singletonPubSub.subscribe({
//         topics: [
//             ...topicsArray
//         ]
//     }).subscribe({
//         next: (data) => {
//             let messageData = data
//             const topic = data[Object.getOwnPropertySymbols(data)[0]]
//             const topicSplitArr = topic.split("/") //eg hcc/stag/5c3fb327-50a3-4413-9076-7ccbab487387/settings/<setting>
//             if (topicSplitArr[3] === "settings") {
//                 messageData = { value: data.value, measureName: topicSplitArr[4] }
//             } else {
//                 if (selectedTableIndex === 0) {
//                     if (topicSplitArr[4] === "screen") {
//                         messageData = { value: data.value, measureName: topicSplitArr[5] }
//                     }
//                 } else if (selectedTableIndex === 1) {
//                     if (topicSplitArr[4] === "telemetry") {
//                         messageData = { value: data.value, measureName: topicSplitArr[6] }
//                     }
//                 }
//             }
//             // console.log('Single device:' + JSON.stringify(messageData))
//             setmqttDeviceOverviewMessages(prev => {
//                 return [...prev, messageData].slice(-120) //only keep latest 120 messages (32*3)+30
//             })
//         },
//         error: (error) => console.error(error),
//         complete: () => console.log('Done')
//     });
// }

// function subscribeControlSettingsTopics(deviceId) { //Single Device control settings data (updating settings)
//     unsubscribeFrom("all")
//     const topicsArr = []
//     for (let index = 0; index < 8; index++) {
//         topicsArr.push('hcc/stag/' + deviceId + '/settings/sp' + (index + 1))
//         topicsArr.push('hcc/stag/' + deviceId + '/settings/diff' + (index + 1))
//         topicsArr.push('hcc/stag/' + deviceId + '/settings/chanen' + (index + 1))
//         topicsArr.push('hcc/stag/' + deviceId + '/settings/spo' + (index + 1))
//     }
//     topicsArr.push('hcc/stag/' + deviceId + '/settings/gbsp')
//     topicsArr.push('hcc/stag/' + deviceId + '/settings/conType')
//     controlSettingsSub.current = singletonPubSub.subscribe({
//         topics: [
//             ...topicsArr
//         ]
//     }).subscribe({
//         next: (data) => {
//             // console.log('CS Message received', data)
//             let messageData = data
//             const topic = data[Object.getOwnPropertySymbols(data)[0]]
//             const topicSplitArr = topic.split("/") //eg hcc/stag/5c3fb327-50a3-4413-9076-7ccbab487387/settings/<setting>
//             const settingKey = topicSplitArr[4]
//             messageData = { value: data.value, settingKey }
//             // console.log('CS Message received' + JSON.stringify(messageData))
//             setmqttControlSettingsMessages(prev => {
//                 return [...prev, messageData].slice(-96) //only keep latest 96 messages
//             })
//         },
//         error: (error) => console.error(error),
//         complete: () => console.log('Done')
//     });
// }
//--->MQTT compile
// function compileMqttDashboardData(data) {
//     let finalStatusValues = {}
//     let finalLWATValues = {}
//     let isForCASEM = false //CASEM presence
//     data.forEach(msg => { //get latest value for device status
//         if ('isConn' in msg) {//lwat
//             finalLWATValues[msg.deviceID] = msg.isConn
//         } else if ('eventType' in msg) { //CASEM presence
//             isForCASEM = true
//             finalStatusValues[msg.clientId] = msg.eventType
//         } else {
//             finalStatusValues[msg.deviceID] = msg.value
//         }
//     });
//     let copyDeviceData = isForCASEM ? JSON.parse(JSON.stringify(casemDeviceData)) : JSON.parse(JSON.stringify(deviceData))
//     Object.keys(finalStatusValues).forEach(deviceId => {
//         const deviceObjIndex = copyDeviceData.findIndex(device => device.id === deviceId)
//         if (isForCASEM) { //CASEM presence
//             let connected = (finalStatusValues[deviceId] === 'connected')
//             copyDeviceData[deviceObjIndex] = { ...copyDeviceData[deviceObjIndex], connected }
//         } else {
//             let status = finalStatusValues[deviceId]
//             if (finalStatusValues[deviceId] === 1 && finalLWATValues?.[deviceId] === 0) {
//                 status = 2 //Disconnected
//             }
//             copyDeviceData[deviceObjIndex] = { ...copyDeviceData[deviceObjIndex], status }
//         }
//     });
//     isForCASEM ? setcasemDeviceData(copyDeviceData) : setdeviceData(copyDeviceData)
// }
// function compileMqttDeviceOverviewData(data) {
//     let copyliveChannelDataToDisplay = JSON.parse(JSON.stringify(liveChannelDataToDisplay))
//     data.forEach(msg => {
//         if (msg.measureName == undefined) return
//         if (msg.measureName === "scrnStat" || msg.measureName === "conType" || msg.measureName === "gbsp") { //HCC globals
//             setliveDeviceProps({ ...liveDeviceProps, [msg.measureName]: msg.value })
//         } else if (msg.measureName === "ambHum" || msg.measureName === "ambTemp" || msg.measureName === "dPoint") { //CASEM globals
//             setliveDeviceProps({ ...liveDeviceProps, [msg.measureName]: (msg.value).toFixed(2) })
//         } else {
//             const channelNum = parseInt(msg.measureName.slice(-1))
//             const measurementName = msg.measureName.slice(0, -1)
//             switch (measurementName) {
//                 case "temp":
//                     copyliveChannelDataToDisplay[channelNum].temp = msg.value
//                     break;
//                 case "relStat":
//                     copyliveChannelDataToDisplay[channelNum].relState = msg.value
//                     break;
//                 case "sp":
//                     copyliveChannelDataToDisplay[channelNum].setpoint = msg.value
//                     break;
//                 case "spo":
//                     copyliveChannelDataToDisplay[channelNum].offset = msg.value
//                     break;
//                 case "diff":
//                     copyliveChannelDataToDisplay[channelNum].diff = msg.value
//                     break;
//                 case "chanen":
//                     copyliveChannelDataToDisplay[channelNum].chanen = msg.value
//                     break;
//                 case "cMode":
//                     copyliveChannelDataToDisplay[channelNum].cMode = msg.value
//                     break;
//                 case "dCycle":
//                     copyliveChannelDataToDisplay[channelNum].dCycle = msg.value
//                     break;
//                 case "cThresh":
//                     copyliveChannelDataToDisplay[channelNum].cThresh = msg.value
//                     break;
//                 case "hStren":
//                     copyliveChannelDataToDisplay[channelNum].hStren = msg.value
//                     break;
//                 case "mTemp":
//                     copyliveChannelDataToDisplay[channelNum].mTemp = msg.value
//                     break;
//                 default:
//                     break;
//             }
//         }
//     });
//     // console.log("LIVE: " + JSON.stringify(copyliveChannelDataToDisplay))
//     setliveChannelDataToDisplay(copyliveChannelDataToDisplay)
// }
// function compileMqttControlSettingsData(data) {
//     let copycontrolSettingsDisplayValues = JSON.parse(JSON.stringify(controlSettingsDisplayValues))
//     data.forEach(msg => {
//         copycontrolSettingsDisplayValues[msg.settingKey] = msg.value
//     });
//     // console.log("CS: ", copycontrolSettingsDisplayValues)
//     setcontrolSettingsDisplayValues(copycontrolSettingsDisplayValues)
// }

// function unsubscribeFrom(stage) { //stage = dashboard / overview / settings / all
//     let subscribeToDashboardTopics = false
//     switch (stage) {
//         case 'dashboard':
//             if (dashboardSub.current !== null) dashboardSub.current.unsubscribe()
//             break;
//         case 'overview':
//             if (deviceOverviewSub.current !== null) deviceOverviewSub.current.unsubscribe()
//             subscribeToDashboardTopics = true
//             break;
//         case 'settings':
//             if (controlSettingsSub.current !== null) controlSettingsSub.current.unsubscribe()
//             subscribeToDashboardTopics = true
//             if (selectedRecord?.id !== undefined) subscribeDeviceOverviewTopics(selectedRecord.id, false)
//             break;
//         case 'all':
//             if (dashboardSub.current !== null) dashboardSub.current.unsubscribe()
//             if (deviceOverviewSub.current !== null) deviceOverviewSub.current.unsubscribe()
//             if (controlSettingsSub.current !== null) controlSettingsSub.current.unsubscribe()
//             break;
//         default:
//             if (dashboardSub.current !== null) dashboardSub.current.unsubscribe()
//             if (deviceOverviewSub.current !== null) deviceOverviewSub.current.unsubscribe()
//             if (controlSettingsSub.current !== null) controlSettingsSub.current.unsubscribe()
//             break;
//     }
//     if (subscribeToDashboardTopics) {
//         const topicsArray = selectedTableIndex === 0 ? dashboardDevicesStatusTopics : casemStatusTopics
//         subscribeDashboardTopics(topicsArray)
//     }
// }

// case "log":
//     data.forEach(dataObj => {
//         topicsOut.push("device/" + dataObj.type + "/" + dataObj.thingName + "/log")
//     });
//     break;
// case "telemetry":
//     topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/#")
//     break;
// case "settings":
//     data.forEach(dataObj => {
//         topicsOut.push("device/" + dataObj.type + "/" + dataObj.thingName + "/settings/#")
//     });
//     break;
// case "presence":
//     data.forEach(dataObj => {
//         topicsOut.push("$aws/events/presence/+/" + dataObj.thingName)
//     });
//     break;
