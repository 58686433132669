import React, { useEffect, useState } from "react";
import { ReactComponent as PowerOffIcon } from '../../assets/PowerOffIcon.svg';
import { ReactComponent as PowerOnIcon } from '../../assets/PowerOnIcon.svg';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const Logs = ({ logStorage, setLogStorage}) => {
  const iconList = [
    <PowerOffIcon style={{
      height: '100%',
      width: '100%'
    }} />,
    <PowerOnIcon style={{
      height: '100%',
      width: '100%',
    }} />,
    <ArrowCircleUpIcon sx={{
      height: '100%',
      width: '100%',
      color: 'green'
    }}
    />,
    <ArrowCircleDownIcon sx={{
      height: '100%',
      width: '100%',
      color: 'blue'
    }} />
  ]
  const [localLogStorage, setLocalLogStorage] = useState([])

  const getFirmwareList = (data) => {
    const firmList = []
    if ('firmVCtrl' in data) {
      firmList.push({
        name: 'Control',
        version: data.firmVCtrl
      })
    }
    if ('firmVTelem' in data) {
      firmList.push({
        name: 'Telemetry',
        version: data.firmVTelem
      })
    }

    return firmList
  }

  const sortByTimestamp = (arr) => {

    // Sort by timestamp in descending order (most recent first)
    const sorted = arr.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    // Use a Map to filter out duplicates by _id
    const uniqueMap = new Map();
    for (const obj of sorted) {
      if (!uniqueMap.has(obj._id)) {
        uniqueMap.set(obj._id, obj);
      }
    }

    // Return the unique objects as an array
    return Array.from(uniqueMap.values());
  }

  useEffect(() => {
    setLocalLogStorage(sortByTimestamp(logStorage))
  }, [logStorage])

  const extractTopic = (topicString) => {
    if (topicString.startsWith('$aws')) {
      return 'PRESENCE'
    } else {
      const topicParts = topicString.split('/')
      return topicParts[topicParts.length - 1].toUpperCase()
    }
  }

  const clearLogStorage = () => {
    setLocalLogStorage([])
    setLogStorage([])
  }

  return (
    <div className="logs">
      <div className='title-header'>
        <h1>Device Logs</h1>
        <div className="clear-logs-tooltip" onClick={() => clearLogStorage()} >
          <DoNotDisturbIcon  sx={{color: 'var(--main-color)'}} />
          <span className="tooltiptext">Clear Logs </span>
        </div>
      </div>
      <div className="log-container">
        {localLogStorage.map((value, index) => {
          return (
            <div key={index} className='log' target='success'>
              <div className='header'>
                <div className='icon'> {iconList[value.iconIndex]}</div>
                <h4>{value.title} </h4>
              </div>
              <div className="id">
                <h4>ID:</h4>
                <p>{value._id} </p>
              </div>
              <div className="topic">
                <h4>Topic:</h4>
                <p>{extractTopic(value.topic)}</p>
              </div>
              <div className="json-container">
                <JsonView
                  data={value.data}
                  shouldExpandNode={allExpanded}
                  style={defaultStyles}
                  className="json-object" />
              </div>
            </div>
          )
        })} {/* Render Stored Logs */}
      </div>
    </div>
  );
};

export default Logs;

