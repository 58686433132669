import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import './styles/CustomCheckBox.css'


export default function CustomCheckBox({ onChange, checked }) {
    return (
        <div className={'checkbox-container ' + (checked && "checkbox-checked")} onClick={() => onChange()}>
            {checked &&
                <CheckIcon sx={{ color: 'white', fontSize: '15px' }} />}
        </div>
    )
}
