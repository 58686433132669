import { React, useState } from 'react'
import './styles/ConfirmUpdateModal.css'

const ConfirmUpdateModal = ({ updateAction, setShowUpdateModal, updateCode, toggleAlert }) => {
  const [userUpdateCode, setUserUpdateCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleUpdateCodeInput = (input) => {
    setUserUpdateCode(input)
  }

  const confirmUpdateCode = () => {
    setIsLoading(true)
    if (userUpdateCode === updateCode) {
      updateAction()
    } else {
      toggleAlert(true, 0, 'Update code not valid')
    }

    setIsLoading(false)
  }

  const handleCloseClick = () => {
    setShowUpdateModal(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'keydown' || e.key === 'Enter') {
      confirmUpdateCode()
    }
  }

  return (
    <>
      <div className='confirm-container'>
        <div className='confirm-modal-background'></div >
        <div className='confirm-modal'>
          <div className='confirm-title'>
            <h1>Confirm Update</h1>
          </div>
          <p className='update-code'> {updateCode} </p>
          <p>Please enter the update code</p>
          <div className='confirm-inputs'>
            <input type="text" className='confirm-input' onChange={(e) => { handleUpdateCodeInput(e.target.value) }} onKeyDown={(e) => handleKeyPress(e)}></input>
            <div className='button-container'>
              <button onClick={() => confirmUpdateCode()}>
                {isLoading ? 'Loading' : 'Confirm'}
              </button>
              <button className='close-btn' onClick={() => handleCloseClick()} >
                Close
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default ConfirmUpdateModal
