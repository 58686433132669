import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './styles/Searchbar.css'

export default function SearchBar({ searchTerm, changeSearchSortOrderFilter }) {
    return (
        <div className="searchbar-container">
            <SearchIcon sx={{ color: '#7a7a7a' }} />
            <input className="searchbar" type="text" id='searchbar' placeholder={"Search..."}
                autoComplete='off'
                onChange={e => changeSearchSortOrderFilter('search',e.target.value)} value={searchTerm} />
            {/* <div className='search-button'  >
                <Search />
            </div> */}
        </div>)
}
