import { useEffect, useState } from 'react'
import './styles/SimCardContainer.css'
import { getSim, getSimEvents } from '../../api/SimsAPI'
import { simStateName } from '../../constants/dataVars'
import AddEditSim from '../../modals/AddEditSim'

const SimCardContainer = ({ record, toggleAlert }) => {
  const [showSimsModal, setShowSimsModal] = useState(false)
  const [sim, setSim] = useState()
  const [simEvents, setSimEvents] = useState([])

  useEffect(() => {
    const fetchSim = async () => {
      const apiSim = await getSim(record?.iccid)
      if (apiSim) {
        setSim(apiSim)
      }
    }

    const fetchSimEvents = async () => {
      const apiSimEvents = await getSimEvents(record?.iccid)
      if (apiSimEvents) {
        setSimEvents(apiSimEvents)
      }
    }

    fetchSimEvents()
    fetchSim()
  }, [])

  function toggleModal(shouldOpen) { //open/close for all modals
    const state = shouldOpen
    setShowSimsModal(state)
  }


  
function formatReadableDate(isoDate) {
  // Create a Date object
  const date = new Date(isoDate);

  // Convert to SAST (UTC+2)
  const offset = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  const sastDate = new Date(date.getTime() + offset);

  // Extract the parts of the date
  const day = String(sastDate.getUTCDate()).padStart(2, '0');
  const month = String(sastDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = sastDate.getUTCFullYear();
  const hours = sastDate.getUTCHours();
  const minutes = String(sastDate.getUTCMinutes()).padStart(2, '0');

  // Return the formatted date
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}


  return (
    <div className='sim-card-container'>
      <AddEditSim
        show={showSimsModal}
        toggleModal={toggleModal}
        toggleAlert={toggleAlert}
        selectedRecord={record}
      />
      <div className="box" >
        <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 480 508" preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <path strokeWidth={9} stroke={'white'} d="M445.707,77.867l-64-64.747C373.6,4.8,362.4,0,350.667,0H97.333C73.013,0,53.28,19.947,53.28,44.48v422.933     c0,24.533,19.733,44.48,44.053,44.48H414.56c24.32,0,44.053-19.947,44.053-44.48V109.227     C458.613,97.493,454.027,86.187,445.707,77.867z M437.28,467.52c0.107,12.693-10.027,23.04-22.72,23.147H97.333     c-12.693-0.107-22.827-10.56-22.72-23.147V44.48c-0.107-12.587,10.027-23.04,22.72-23.147h253.333c5.973,0,11.84,2.453,16,6.72     L430.56,92.8c4.267,4.373,6.72,10.347,6.72,16.427V467.52z" />
          <foreignObject x={69} y={16} width={720} height={960}>
            <div className='sim'>
              <h1>{record?.serialNumber}</h1>
              <div className='row'>
                <h3> IMSI: </h3>
                <p>{sim?.imsi}</p>
              </div>
              <div className='row'>
                <h3> ICCID: </h3>
                <p>{sim?.iccid}</p>
              </div>
              <div className='row'>
                <h3> Current State: </h3>
                <p>{simStateName[sim?.state]}</p>
              </div>
              <div className='row'>
                <h3>Data Usage: </h3>
                <p>{sim?.dataUsage}</p>
              </div>
              <div className='row'>
                <h3>Carrier : </h3>
                <p>{sim?.mobileCarrier}</p>
              </div>
              <div className='row'>
                <h3>Technology : </h3>
                <p>{sim?.technology}</p>
              </div>
              <div className='row'>
                <h3>Signal Strength : </h3>
                <p>{sim?.signalStrength}</p>
              </div>
              <button onClick={() => toggleModal(true)}> Edit Sim</button>
            </div>
          </foreignObject>
        </svg>
      </div>

      <div className='sim-events'>
        <h1>Sim Events</h1>
        {simEvents.map((event, index) => {
          return (
            <div className='event' key={index}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>{event?.name}</h3>
                <h4>{formatReadableDate(event?.createdAt)}</h4>
              </div>
              <p>{event?.description}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SimCardContainer
